import { HiChatBubbleOvalLeft, HiOutlineChatBubbleOvalLeft } from "react-icons/hi2";
import UnreadMessagesBadge from "../UnreadMessagesBadge";
import unreadChatsCounts from "state/unreadChatsState";

type ChatNavIconType = {
  selected: boolean;
};

const ChatNavIcon = ({ selected = false }: ChatNavIconType) => {
  const { unreadChatsCount } = unreadChatsCounts();

  return (
    <div className={"relative"}>
      <UnreadMessagesBadge className="absolute -top-0.5 left-2 " counter={unreadChatsCount} />
      {selected ? (
        <HiChatBubbleOvalLeft className={"h-7 w-7 sm:h-6 sm:w-6"} />
      ) : (
        <HiOutlineChatBubbleOvalLeft className={"h-7 w-7 sm:h-6 sm:w-6"} />
      )}
    </div>
  );
};

const ChatNavIconSelected = () => <ChatNavIcon selected />;

export { ChatNavIcon, ChatNavIconSelected };
