import Button from "components/basics/Button";
import PopupBottom from "components/molecules/PopupBottom";
import { supportEmail } from "const";
import { useAgeVerification } from "hooks/checkout/useAgeVerification.hook";
import useChat from "hooks/useChat.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineExclamationCircle } from "react-icons/hi2";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  className?: string;
};

const AgeVerificationFailedPopup = ({ open, setOpen, className }: Props) => {
  const { t } = useTranslation();
  const { openChatWithMaloumOfficial } = useChat();
  const { onVerifyNow, loading } = useAgeVerification();
  const navigate = useStatefulNavigate();

  return (
    <PopupBottom open={open} setOpen={setOpen} className={className}>
      <div className="flex flex-col items-center justify-center gap-6 px-4">
        <div className="flex flex-col items-center justify-center gap-4">
          <div className="flex size-14 items-center justify-center rounded-full bg-red-100 text-red-900">
            <HiOutlineExclamationCircle className="size-6" />
          </div>
          <div className="flex flex-col gap-1 text-center">
            <div className="mt-1 font-semibold">{t("popups.ageVerification.failed.description")}</div>
            <div className="text-sm text-gray-600">{t("popups.ageVerification.failed.description2")}</div>
          </div>
        </div>
        <div className="text-center text-sm text-gray-600">
          <Trans i18nKey="smallBusiness.questions">
            <button type="button" className="underline" onClick={() => openChatWithMaloumOfficial()} />
            <a href={`mailto:${supportEmail}`} className="underline" />
          </Trans>
        </div>
        <div className="mt-2 flex w-full justify-center gap-2.5">
          <Button
            text={t("backToHome")}
            onClick={() => {
              setOpen(false);
              navigate("/search");
            }}
            className="w-full"
            variant="secondary"
          />
          <Button text={t("verification.tryAgain")} onClick={onVerifyNow} className="w-full" disabled={loading} />
        </div>
      </div>
    </PopupBottom>
  );
};

export default AgeVerificationFailedPopup;
