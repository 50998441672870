import {
  ChatProductMessageContentDto,
  MediaMessageContentDto,
  MessageContentType,
  TextMessageContentDto,
  TipMessageContentDto,
} from "@neolime-gmbh/api-gateway-client";

export type MessageContentDto =
  | TextMessageContentDto
  | MediaMessageContentDto
  | TipMessageContentDto
  | ChatProductMessageContentDto;

export const isChatProductMessageContent = (content: MessageContentDto): content is ChatProductMessageContentDto => {
  return content.type === MessageContentType.CHAT_PRODUCT;
};

export const isTipMessageContent = (content: MessageContentDto): content is TipMessageContentDto => {
  return content.type === MessageContentType.TIP;
};

export const isTextMessageContent = (content: MessageContentDto): content is TextMessageContentDto => {
  return content.type === MessageContentType.TEXT;
};

export const isMediaMessageContent = (content: MessageContentDto): content is MediaMessageContentDto => {
  return content.type === MessageContentType.MEDIA;
};
