import { useFirstline } from "@first-line/firstline-react";
import { DisplayableMediaDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import StatefulLink from "components/atoms/utils/StatefulLink";
import ProfileImage from "components/basics/ProfileImage";
import Media from "components/media/Media";

type SuggestedCreatorCardProps = {
  backgroundImage: DisplayableMediaDto;
  profileImage?: DisplayableMediaDto;
  rank?: number;
  username: string;
  isVerified: boolean;
  fullWidth?: boolean;
  "data-testid"?: string;
};

const SuggestedCreatorCard = ({
  fullWidth = false,
  rank,
  backgroundImage,
  profileImage,
  username,
  isVerified,
  "data-testid": dataTestId,
}: SuggestedCreatorCardProps) => {
  const { user: authUser } = useFirstline();
  const isOwnContent = authUser?.username === username;

  return (
    <StatefulLink
      to={`/creator/${username}?tab=posts`}
      className={classNames("relative flex shrink-0 items-end overflow-hidden", {
        "aspect-[3/4] w-full": fullWidth,
        "h-[200px] w-[150px]": !fullWidth,
      })}
      data-testid={dataTestId}
    >
      {rank && (
        <div className="absolute right-3 top-3 z-[2] flex h-5 min-w-[1.25rem] items-center justify-center rounded-md bg-gray-900/60 px-1">
          <span className="align-middle text-sm font-semibold text-white">{rank}</span>
        </div>
      )}
      {backgroundImage ? (
        <div className="absolute z-0 h-full w-full overflow-hidden rounded-md">
          <Media allowFullScreen={false} media={backgroundImage} thumbnail={backgroundImage} />
        </div>
      ) : (
        <div className="absolute z-0 h-full w-full rounded-md bg-red-100" />
      )}

      <div className="z-[10] flex w-full flex-col items-center gap-1 p-3">
        <div className="h-16 w-16">
          <ProfileImage
            url={profileImage?.url}
            className="h-16 w-16 ring-1 ring-white"
            uploadStatus={profileImage?.uploadStatus}
            isCurrentUser={isOwnContent}
            spinnerClassName="w-5 h-5"
          />
        </div>
        <div className="flex w-full max-w-full items-center justify-center gap-1 text-sm font-semibold text-white">
          <div className="truncate">{username}</div>
          {isVerified && <VerifiedUserIcon className="h-4 w-4" />}
        </div>
      </div>
      {/* dark gradient to make username always visible */}
      <div className="absolute bottom-0 left-0 z-[1] h-28 w-full rounded-b-md bg-gradient-to-t from-gray-800/80 to-gray-500/0" />
    </StatefulLink>
  );
};

type SuggestedCreatorCardLoadingProps = {
  fullWidth?: boolean;
  "data-testid"?: string;
};

const SuggestedCreatorCardLoading = ({
  fullWidth = false,
  "data-testid": dataTestId,
}: SuggestedCreatorCardLoadingProps) => {
  return (
    <div
      className={classNames("relative flex shrink-0 items-end overflow-hidden", {
        "aspect-[3/4] w-full": fullWidth,
        "h-[200px] w-[150px]": !fullWidth,
      })}
      data-testid={dataTestId}
    >
      <div className="absolute -z-[2] h-full w-full animate-pulse rounded-md bg-gray-200" />

      <div className="z-[10] flex w-full flex-col items-center gap-1 p-3">
        <div className="h-16 w-16 animate-pulse rounded-full bg-gray-200" />
        <div className="mt-2 h-3 w-1/2 animate-pulse rounded-md bg-gray-200" />
      </div>
      <div className="absolute bottom-0 left-0 z-[1] h-28 w-full rounded-b-md bg-gradient-to-t from-gray-800/80 to-gray-500/0" />
    </div>
  );
};

export default SuggestedCreatorCard;
export { SuggestedCreatorCardLoading };
