import { ChatDto, MessagePreviewDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import StatefulLink from "components/atoms/utils/StatefulLink";
import useCurrency from "hooks/useCurrency.hook";
import useQueryManipulator from "hooks/useQueryManipulator";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePhoto } from "react-icons/hi2";
import { generateTimeTag } from "../../helper/dateAndTimeHelper";
import ProfileImage from "../basics/ProfileImage";
import { useParams, useSearchParams } from "react-router-dom";

type ChatMessageItemProps = {
  message?: MessagePreviewDto;
};

const ChatMessageItem = ({ message }: ChatMessageItemProps) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  const chatPreviewContent = useMemo(() => {
    const tip = message?.tip ? displayCurrency(message.tip) : "";
    switch (message?.type) {
      case "chat_product":
        return (
          <>
            <HiOutlinePhoto />
            <span title={t("exclusiveContent") as string}>{t("exclusiveContent")}</span>
          </>
        );

      case "media":
        return (
          <>
            <HiOutlinePhoto />
            <span title={t("mediaSingular") as string}>{t("mediaSingular")}</span>
          </>
        );
      case "text":
        return (
          <>
            <span className="truncate" title={message.text}>
              {message.text}
            </span>
          </>
        );
      case "tip":
        return (
          <>
            <span title={tip}>{tip}</span>
          </>
        );
      default:
        return <span>{t("no-messages")}</span>;
    }
  }, [message?.type, message?.text, message?.tip]);

  return <div className="flex items-center gap-2">{chatPreviewContent}</div>;
};

type Props = {
  chatItem: ChatDto;
};

const ChatItem = ({ chatItem }: Props) => {
  const { invalidateChat } = useQueryManipulator();

  const { id } = useParams();

  const isSelected = chatItem._id === id;

  const user = chatItem.chatPartner;
  const chatDate = chatItem.messages?.at(-1)
    ? generateTimeTag(new Date(chatItem.messages?.at(-1)!.sentAt))
    : generateTimeTag(new Date(chatItem.createdAt));

  // get "tab" from searchParams
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const tabSearchParam = tab === "request" ? `?tab=request` : "";

  return (
    <>
      <StatefulLink
        className={classNames(
          "flex cursor-pointer items-center gap-3 px-3.5 py-4",
          isSelected ? "rounded-sm bg-beige-400 active:bg-beige-500" : "bg-white hover:bg-beige-300 active:bg-white",
        )}
        to={`/chat/${chatItem._id}${tabSearchParam}`}
        state={{ fromInbox: chatItem.inbox }}
        onClick={() => invalidateChat(chatItem._id)}
      >
        <ProfileImage
          url={user?.profilePictureThumbnail?.url}
          className={"h-10 min-h-10 w-10 min-w-10 flex-none shrink-0"}
          uploadStatus={user?.profilePictureThumbnail?.uploadStatus}
        />
        <div className="grid shrink grow grid-cols-1 gap-0.5">
          <div className="flex items-center gap-0.5">
            <div
              className={classNames(
                "notranslate line-clamp-1 flex grow items-center gap-x-1 text-base",
                chatItem.unreadMessages && "font-semibold",
              )}
            >
              <span className="truncate">{user?.nickname || user?.username}</span>{" "}
              {user?.isVerified && <VerifiedUserIcon />}
            </div>
            <div className={classNames("line-clamp-1 shrink-0 truncate text-xs", !isSelected && "text-gray-500")}>
              {chatDate}
            </div>
          </div>
          <div className="flex w-full items-center">
            <div
              className={classNames("grow truncate text-sm", {
                "font-medium": chatItem.unreadMessages,
                "text-gray-400": !chatItem.unreadMessages && !isSelected,
              })}
            >
              <ChatMessageItem message={chatItem.messages?.at(-1)} />
            </div>
            {chatItem.unreadMessages && (
              <div
                className="ml-2 h-3 w-3 shrink-0 rounded-full bg-red-900"
                data-testid={"unread-messages-indicator"}
              />
            )}
          </div>
        </div>
      </StatefulLink>
      <hr className="h-px w-full border-0 bg-gray-100" />
    </>
  );
};

export default ChatItem;
