import classNames from "classnames";
import { ElementType, ReactNode } from "react";

type Props<C extends ElementType> = {
  hasPadding?: boolean;
  hasGrow?: boolean;
  className?: string;
  id?: string;
  children: ReactNode;
  hasStickyBottom?: boolean;
  isLoading?: boolean;
  noDesktopPadding?: boolean;
  hideScrollbar?: boolean;
  as?: C;
  "data-testid"?: string;
};

const Container = <C extends ElementType>({
  hasPadding = true,
  hasGrow = true,
  hasStickyBottom = false,
  isLoading = false,
  className,
  id,
  children,
  noDesktopPadding = false,
  hideScrollbar = false,
  as,
  "data-testid": dataTestId,
}: Props<C>) => {
  const Component = as || "div";

  // if we need sticky  bottom, we dont need another classes; for now it is the quickliest solution
  if (hasStickyBottom) {
    hasPadding = false;
    hasGrow = false;
  }

  return (
    <Component
      id={id}
      className={classNames("mx-auto flex w-full max-w-xl flex-col", className, {
        "px-4": hasPadding,
        "md:px-4": !hasPadding && !noDesktopPadding,
        "scrollbar-hide": hideScrollbar,
        grow: hasGrow,
        "h-full justify-between overflow-hidden": hasStickyBottom,
      })}
      data-testid={dataTestId}
    >
      {hasStickyBottom ? (
        <div
          className={classNames(
            "flex flex-1 flex-col p-4 pt-0",
            { "overflow-auto": !isLoading },
            { "overflow-hidden": isLoading },
          )}
        >
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </Component>
  );
};

export default Container;
