import { create } from "zustand";

type TChatScrollStore = {
  messageScrollPosition: number | null;
  setMessageScroll: (position: number | null) => void;
  resetMessageScroll: () => void;
};

export const useChatScrollStore = create<TChatScrollStore>((set) => ({
  messageScrollPosition: null,
  setMessageScroll: (position) => set({ messageScrollPosition: position }),
  resetMessageScroll: () => set({ messageScrollPosition: null }),
}));
