import { useEffect, useRef, useState } from "react";
import { useChatScrollStore } from "state/chatScrollState";
import useMessages from "./useMessages.hook";
import { MessageDto } from "@neolime-gmbh/api-gateway-client";
import { useSavedScroll } from "./useSavedScrollPosition.hook";

export const useReverseInfiniteScroll = ({
  bottomRef,
  chatId,
  inView,
}: {
  bottomRef: React.RefObject<HTMLDivElement>;
  chatId: string;
  inView: boolean;
}) => {
  const { messages, isLoading, isFetching, hasMore, fetchMore } = useMessages(chatId);
  const [firstLoad, setFirstLoad] = useState(true);
  const [lastHeight, setLastHeight] = useState(0);
  const [lastMessage, setLastMessage] = useState<MessageDto | undefined>();

  const scrollPositionRef = useRef<number | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useSavedScroll({ scrollContainerRef });

  const toBottom = () => bottomRef.current?.scrollIntoView?.();

  useEffect(() => {
    if (firstLoad && messages.length > 0) {
      toBottom();
      setFirstLoad(false);
    }
    if (messages.length > 0) {
      if (messages[0]._id !== lastMessage?._id) toBottom();
      setLastMessage(messages[0]);
    }
  }, [messages]);

  useEffect(() => {
    if (inView && hasMore && !isFetching && !isLoading && !firstLoad) fetchMore();
  }, [inView]);

  useEffect(() => {
    if (messages.length === 0) return;
    if (lastHeight !== 0) {
      const container = scrollContainerRef.current;
      if (!container) return;
      if (messages[0]._id !== lastMessage?._id) return;

      const newHeight = container.scrollHeight;
      const currentScroll = Math.max(scrollPositionRef.current ?? 0, 0);
      const heightDiff = newHeight - lastHeight;

      if (heightDiff > 0 && currentScroll < container.clientHeight) {
        container.style.transform = "translateY(0)";
        container.scrollTop = heightDiff;
      }
    }

    requestAnimationFrame(() => {
      setLastHeight(scrollContainerRef.current?.scrollHeight ?? 0);
    });
  }, [messages]);

  const handleScroll = () => {
    const currentScroll = scrollContainerRef.current?.scrollTop ?? 0;
    scrollPositionRef.current = currentScroll;
  };

  const setMessageScroll = useChatScrollStore((state) => state.setMessageScroll);

  return {
    scrollContainerRef,
    scrollPositionRef,
    handleScroll,
    setMessageScroll,
    messages,
    isLoading,
    isFetching,
    hasMore,
    fetchMore,
  };
};
