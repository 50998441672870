import { ChatDto } from "@neolime-gmbh/api-gateway-client";
import EmptyChatList from "components/chats/EmptyChatList";
import useQueryManipulator from "hooks/useQueryManipulator";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import ChatItem from "./ChatItem";
import LoadingChatItem from "./LoadingChatItem";

type Props = {
  chats: ChatDto[];
  bottomRef: (node?: Element | null) => void;
  isLoading: boolean;
};

const ChatsList = ({ chats, bottomRef, isLoading }: Props) => {
  const { id } = useParams();
  const { invalidateMessages } = useQueryManipulator();

  // create chat list component
  const renderItemComponent = useCallback(
    (item: ChatDto) => <ChatItem chatItem={item} key={`${item._id}-chat-item-${item.messages.at(-1)?.sentAt}`} />,
    [chats],
  );

  useEffect(() => {
    if (id) {
      invalidateMessages(id);
    }
  }, [id]);

  // create loading chat list component
  const renderLoadingItemComponent = useCallback((key: number) => <LoadingChatItem key={key} />, []);

  return (
    <>
      {chats.map(renderItemComponent)}
      <div className="absolute bottom-[10rem]" ref={bottomRef} />
      {chats.length === 0 && !isLoading && <EmptyChatList />}
      {isLoading &&
        Array(5)
          .fill(null)
          .map((_, key) => renderLoadingItemComponent(key))}
    </>
  );
};

export default ChatsList;
