import { createContext } from "react";
import axios from "axios";
import { env } from "../env";
import { MaloumClient } from "@neolime-gmbh/api-gateway-client/MaloumClient";
import { TrackingServiceClient } from "@neolime-gmbh/tracking-service-client/TrackingServiceClient";
import { useFirstline } from "@first-line/firstline-react";

//@ts-expect-error no default values
const MaloumClientContext = createContext<{
  maloumClient: MaloumClient;
  trackingServiceClient: TrackingServiceClient;
}>();
export default MaloumClientContext;

type ExchangeCodeResponse = {
  access_token: string;
  id_token: string;
  refresh_token: string;
};

export const MaloumClientProvider = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, getAccessToken, doExchangeOrRefresh } = useFirstline();

  const maloumClient = new MaloumClient({
    BASE: env.VITE_NEOLIME_BACKEND_URL,
    TOKEN: isAuthenticated ? () => getAccessToken() : undefined,
  });

  const trackingServiceClient = new TrackingServiceClient({
    BASE: env.VITE_NEOLIME_TRACKING_SERVICE_URL,
  });

  let currentRefreshingPromise: Promise<ExchangeCodeResponse> | undefined = undefined;
  axios.interceptors.response.use(undefined, async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry && isAuthenticated) {
      originalRequest._retry = true;

      if (!currentRefreshingPromise) {
        currentRefreshingPromise = doExchangeOrRefresh();

        const exchangeCodeResponse = await currentRefreshingPromise;
        maloumClient.request.config.TOKEN = exchangeCodeResponse.access_token;

        currentRefreshingPromise = undefined;
        originalRequest.headers.Authorization = `Bearer ${exchangeCodeResponse.access_token}`;
        return axios(originalRequest);
      }

      return currentRefreshingPromise.then((exchangeCodeResponse) => {
        originalRequest.headers.Authorization = `Bearer ${exchangeCodeResponse.access_token}`;
        return axios(originalRequest);
      });
    }
    return Promise.reject(error);
  });

  return (
    <MaloumClientContext.Provider value={{ maloumClient, trackingServiceClient }}>
      {children}
    </MaloumClientContext.Provider>
  );
};
