import {
  AddressDto,
  CheckoutChatProductDto,
  CheckoutProductDto,
  CheckoutResponseDto,
  CheckoutSubscriptionDto,
  CheckoutTipDto,
  PaymentMethod,
  PaymentMethodDto,
  PrivateUserDto,
} from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import CheckoutStepper from "components/CheckoutStepper";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import PopUp from "components/popup/PopUp";
import MaloumClientContext from "contexts/MaloumClientContext";
import { getThreeDSBrowserData } from "helper/checkoutHelper";
import { useCheckout, useCheckoutItem } from "hooks/checkout";
import SelectCountryPage from "pages/SelectCountry";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import useUserStore from "state/userState";
import { ReactComponent as BitcoinSVG } from "svg/payment/btc-logo.svg";
import { ReactComponent as EthereumSVG } from "svg/payment/eth-logo.svg";
import { ReactComponent as KlarnaSVG } from "svg/payment/klarna.svg";
import { ReactComponent as MastercardSVG } from "svg/payment/mastercard.svg";
import { ReactComponent as PaypalSVG } from "svg/payment/paypal.svg";
import { ReactComponent as VisaSVG } from "svg/payment/visa.svg";
import CartItem from "./checkoutComponents/CartItem";
import Note from "./checkoutComponents/Note";
import Payment from "./checkoutComponents/Payment";
import Shippment from "./checkoutComponents/Shippment";
import { PaymentOption } from "types";
import { CountryDisplayItem } from "const/countries/countryList";
import { CheckoutPosthogEvents, useCheckoutPosthog } from "hooks/useCheckoutPosthog";

const Checkout = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { maloumClient } = useContext(MaloumClientContext);
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { capture } = useCheckoutPosthog();
  const { checkoutTip, checkoutProduct, checkoutSubscription, checkoutChatProduct } = useCheckout();

  const [note, setNote] = useState<string>();
  const [selectedCountry, setSelectedCountry] = useState(user.address.country);
  const [shippingData, setShippingData] = useState<(AddressDto & { firstName: string; lastName: string }) | null>(null);
  const [showCountrySelect, setShowCountrySelect] = useState(false);
  const [paymentError, setPaymentError] = useState<string | undefined>(undefined);

  const type = searchParams.get("type") as "SUBSCRIPTION" | "PRODUCT" | "TIP" | "CHAT_PRODUCT" | "CHAT_UNLOCK" | null;
  const id = searchParams.get("id");
  const amount = searchParams.get("amount") ? parseFloat(searchParams.get("amount") as string) : null;

  const [step, setStep] = useState(type === "PRODUCT" ? 1 : 2);

  const removePaymentMethod = async (referenceUUID: string) => {
    await maloumClient.users
      .removeSavedPaymentInformation(referenceUUID)
      .then((u: PrivateUserDto) => useUserStore.setState({ user: u }));
  };

  const paymentOptions = useMemo(
    (): PaymentOption[] => [
      ...user.storedPaymentInformation.map((p) => {
        return {
          description:
            p.paymentMethod.valueOf() === "PAYPAL"
              ? t("checkout.paypal")
              : `${t("checkout.creditCard")} **** **** **** ${p.hint}`,
          paymentMethod: p.paymentMethod,
          referenceId: p.referenceUUID,
          children: (
            <div className={"flex items-center gap-2"}>
              <button className="text-sm text-red-900" onClick={() => removePaymentMethod(p.referenceUUID)}>
                {t("remove")}
              </button>
            </div>
          ),
        };
      }),
      {
        description: t("checkout.creditCard"),
        paymentMethod: PaymentMethod.CREDIT_CARD,
        children: (
          <div className={"flex items-center gap-2"}>
            <VisaSVG className="h-5" />
            <MastercardSVG className="h-5" />
          </div>
        ),
      },
      {
        description: t("checkout.paypal"),
        paymentMethod: PaymentMethod.PAYPAL,
        children: <PaypalSVG className="h-5" />,
      },
      ...(type !== "SUBSCRIPTION"
        ? [
            {
              description: t("checkout.directBankTransfer"),
              paymentMethod: PaymentMethod.SOFORT,
              children: <KlarnaSVG className="h-6 w-auto" />,
            },
            {
              description: t("checkout.crypto"),
              paymentMethod: PaymentMethod.COINGATE,
              children: (
                <div className="flex items-center gap-2">
                  <BitcoinSVG className="h-6 w-auto" /> <EthereumSVG className="h-6 w-auto" />
                </div>
              ),
            },
          ]
        : []),
    ],
    [user, type],
  );

  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOption>(paymentOptions[0]);

  const { item, price } = useCheckoutItem(id, type, amount, selectedCountry, selectedPaymentOption.paymentMethod);

  const handleAddressSubmit = (values: {
    firstName: string;
    lastName: string;
    city: string;
    postalCode: string;
    line1: string;
    line2: string;
  }) => {
    setShippingData({ ...values, country: selectedCountry });
    setStep(2);
  };

  useEffect(() => {
    if (showCountrySelect) window.scrollTo({ top: 0 });
  }, [showCountrySelect]);

  const selectCountry = (country: CountryDisplayItem) => {
    setSelectedCountry(country.key.toUpperCase());
    setShowCountrySelect(false);
  };

  const handleBuyTip = async () => {
    const body: CheckoutTipDto = {
      netAmount: price?.net,
      creatorId: searchParams.get("id")!,
      address: { country: selectedCountry },
      paymentMethod: {
        paymentMethod: selectedPaymentOption.paymentMethod,
        referenceId: selectedPaymentOption.referenceId,
      } satisfies PaymentMethodDto,
    };
    // @ts-expect-error 3DS typing is not correct
    if (selectedPaymentOption.referenceId) body.threeDSBrowserData = getThreeDSBrowserData();

    let checkoutResponseDto: CheckoutResponseDto;

    try {
      checkoutResponseDto = await checkoutTip(body);
      capture(CheckoutPosthogEvents.TipPaymentStart);
    } catch (e) {
      capture(CheckoutPosthogEvents.TipPaymentError, {
        error: (e as Error).message,
      });
      throw e;
    }
    // Google Tag Manager: Tip Go To Payment Event
    // @ts-expect-error window.dataLayer is not defined
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error window.dataLayer is not defined
    window.dataLayer.push({
      event: "tip_go_to_payment",
      creator_id: id,
      net_price: price?.net,
    });
    window.location.replace(checkoutResponseDto.redirectUrl);
  };

  const handleBuyChatProduct = async () => {
    if (!item) return;

    const body: CheckoutChatProductDto = {
      messageId: item._id,
      address: { country: selectedCountry },
      paymentMethod: {
        paymentMethod: selectedPaymentOption.paymentMethod,
        referenceId: selectedPaymentOption.referenceId,
      } satisfies PaymentMethodDto,
    };
    // @ts-expect-error 3DS typing is not correct
    if (selectedPaymentOption.referenceId) body.threeDSBrowserData = await getThreeDSBrowserData();

    let checkoutResponseDto: CheckoutResponseDto;

    try {
      checkoutResponseDto = await checkoutChatProduct(body);
      capture(CheckoutPosthogEvents.ChatProductPaymentStart);
      window.location.replace(checkoutResponseDto.redirectUrl);
    } catch (e) {
      capture(CheckoutPosthogEvents.ChatProductPaymentError, {
        error: (e as Error).message,
      });
      setPaymentError("checkout.error.chatProductNotAvailable");
      throw e;
    }
  };

  const handleBuyProduct = async () => {
    if (!item) return;

    const { firstName, lastName, ...address } = shippingData!;
    if (address.line2 === "") {
      delete address.line2;
    }
    const body: CheckoutProductDto = {
      productId: item._id,
      notes: note,
      firstName,
      lastName,
      // make sure postal code is always a string
      address: { ...address, postalCode: `${address.postalCode}` },
      paymentMethod: {
        paymentMethod: selectedPaymentOption.paymentMethod,
        referenceId: selectedPaymentOption.referenceId,
      } satisfies PaymentMethodDto,
    };
    // @ts-expect-error 3DS typing is not correct
    if (selectedPaymentOption.referenceId) body.threeDSBrowserData = await getThreeDSBrowserData();

    let checkoutResponseDto: CheckoutResponseDto;

    try {
      checkoutResponseDto = await checkoutProduct(body);
      capture(CheckoutPosthogEvents.ProductPaymentStart);
    } catch (e) {
      capture(CheckoutPosthogEvents.ProductPaymentError, {
        error: (e as Error).message,
      });
      throw e;
    }
    if (item && "name" in item) {
      // Google Tag Manager: Product Go To Payment Event
      // @ts-expect-error window.dataLayer is not defined
      window.dataLayer = window.dataLayer || [];
      // @ts-expect-error window.dataLayer is not defined
      window.dataLayer.push({
        event: "product_go_to_payment",
        items: [
          {
            item_id: item._id,
            item_name: item.name,
            net_price: price?.net,
            interests: item.categories.map((category: { _id: string }) => category._id),
            creator_id: item.createdBy._id,
          },
        ],
      });
    }
    window.location.replace(checkoutResponseDto.redirectUrl);
  };

  const handleBuySubscription = async () => {
    const creatorId = item?._id ?? (id as string);

    const body: CheckoutSubscriptionDto = {
      creatorId: creatorId,
      address: { country: selectedCountry },
      paymentMethod: {
        paymentMethod: selectedPaymentOption.paymentMethod,
        referenceId: selectedPaymentOption.referenceId,
      } satisfies PaymentMethodDto,
    };
    // @ts-expect-error 3DS typing is not correct
    if (selectedPaymentOption.referenceId) body.threeDSBrowserData = await getThreeDSBrowserData();

    let checkoutResponseDto: CheckoutResponseDto;

    try {
      checkoutResponseDto = await checkoutSubscription(body);
      capture(CheckoutPosthogEvents.SubscriptionPaymentStart);
    } catch (e) {
      capture(CheckoutPosthogEvents.SubscriptionPaymentError, {
        error: (e as Error).message,
      });
      throw e;
    }
    // Google Tag Manager: Subscription Go To Payment Event
    // @ts-expect-error window.dataLayer is not defined
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error window.dataLayer is not defined
    window.dataLayer.push({
      event: "subscription_go_to_payment",
      creator_id: creatorId,
      net_price_per_month: price?.net,
    });
    window.location.replace(checkoutResponseDto.redirectUrl);
  };

  const handleBuyChatUnlock = async () => {
    if (!item) return;

    const body: CheckoutTipDto = {
      netAmount: price?.net,
      creatorId: item._id,
      address: { country: selectedCountry },
      paymentMethod: {
        paymentMethod: selectedPaymentOption.paymentMethod,
        referenceId: selectedPaymentOption.referenceId,
      } satisfies PaymentMethodDto,
      unlocksExclusiveChat: true,
    };
    // @ts-expect-error 3DS typing is not correct
    if (selectedPaymentOption.referenceId) body.threeDSBrowserData = await getThreeDSBrowserData();

    let checkoutResponseDto: CheckoutResponseDto;

    try {
      checkoutResponseDto = await checkoutTip(body);
      capture(CheckoutPosthogEvents.TipPaymentStart);
    } catch (e) {
      capture(CheckoutPosthogEvents.TipPaymentError, {
        error: (e as Error).message,
      });
      throw e;
    }
    window.location.replace(checkoutResponseDto.redirectUrl);
  };

  // TODO: use backend dto as soon as available
  const handleBuy = async () => {
    switch (type) {
      case "TIP":
        await handleBuyTip();
        break;
      case "CHAT_PRODUCT":
        await handleBuyChatProduct();
        break;
      case "PRODUCT":
        await handleBuyProduct();
        break;
      case "SUBSCRIPTION":
        await handleBuySubscription();
        break;
      case "CHAT_UNLOCK":
        await handleBuyChatUnlock();
        break;
    }
  };

  if (!type || !id) return <StatefulNavigate to="/" />;

  return (
    <>
      <div className={classNames({ hidden: !showCountrySelect })}>
        <SelectCountryPage
          selected={selectedCountry}
          onBack={() => setShowCountrySelect(false)}
          onSubmit={selectCountry}
          useOther={true}
        />
      </div>
      <div className={classNames("", { hidden: showCountrySelect })}>
        <Layout hideNavigationMobile>
          <HeaderBar>
            <HeaderBar.SubPage>
              <HeaderBar.Left>
                <HeaderBar.BackButton />
              </HeaderBar.Left>
              <HeaderBar.Center>
                <HeaderBar.Title>{step === 1 ? t("checkout.shipping.title") : t("checkout.checkout")}</HeaderBar.Title>
              </HeaderBar.Center>
              <HeaderBar.Right />
            </HeaderBar.SubPage>
            <CheckoutStepper maxStep={2} currStep={step} className="pb-4 pt-2" />
          </HeaderBar>
          <Container className="mt-4">
            <div className="text-2xl font-semibold">{t("checkout.cart")}</div>
            <CartItem
              item={item}
              type={type}
              price={price}
              showVatHint={selectedCountry === undefined}
              className="mt-2"
            >
              {type === "PRODUCT" && <Note note={note} setNote={setNote} />}
            </CartItem>
            {step === 1 && (
              <Shippment
                submit={handleAddressSubmit}
                selectedCountry={selectedCountry}
                showCountrySelect={() => setShowCountrySelect(true)}
                price={price}
                className="mt-6"
              />
            )}
            {step === 2 && (
              <>
                <Payment
                  price={price}
                  paymentOptions={paymentOptions}
                  selectedPaymentOption={selectedPaymentOption}
                  setSelectedPaymentOption={setSelectedPaymentOption}
                  type={type}
                  selectedCountry={selectedCountry}
                  showCountrySelect={() => setShowCountrySelect(true)}
                  handleBuy={handleBuy}
                  className="mt-6"
                />
                <PopUp
                  isOpen={!!paymentError}
                  onClose={() => setPaymentError(undefined)}
                  title={t(paymentError!) || ""}
                />
              </>
            )}
          </Container>
        </Layout>
      </div>
    </>
  );
};

export default Checkout;
