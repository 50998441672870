import { CheckoutTipDto, PrivateUserDto, StoredPaymentInformationDto } from "@neolime-gmbh/api-gateway-client";
import AgeVerificationPopup from "components/age-verification/AgeVerificationPopup";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import Button from "components/basics/Button";
import PopupBottom from "components/molecules/PopupBottom";
import OrderType from "enums/OrderType";
import { Form, Formik } from "formik";
import { getThreeDSBrowserData } from "helper/checkoutHelper";
import { useAgeVerification, useCheckout, useCheckoutItem, useStoredCreditCard } from "hooks/checkout";
import { CheckoutPosthogEvents, useCheckoutPosthog } from "hooks/useCheckoutPosthog";
import useCurrency from "hooks/useCurrency.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useMutation } from "react-query";
import { createSearchParams } from "react-router-dom";
import useUserStore from "state/userState";
import * as Yup from "yup";
import FormikPriceInput from "../basics/from/FormikPriceInput";

type Props = {
  creatorId: string;
  open: boolean;
  setOpen: (v: boolean) => void;
  className?: string;
  "data-testid"?: string;
};

const TipPopupBottom = ({ creatorId, open, setOpen, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const storedCreditCard = useStoredCreditCard(user);
  const { displayCurrency } = useCurrency();
  const { capture } = useCheckoutPosthog();
  const { checkoutTip } = useCheckout();
  const { userNeedsAgeVerification } = useAgeVerification();

  const [ageVerificationPopupOpen, setIsAgeVerificationPopupOpen] = useState(false);
  const [overrideRedirect, setOverrideRedirect] = useState<string | undefined>(undefined);

  const handleTipCheckout = (tip: string) => {
    if (tip) {
      capture(CheckoutPosthogEvents.TipCheckout);
      navigate({
        pathname: "/checkout",
        search: createSearchParams({
          type: OrderType.Tip,
          amount: tip?.toString().replaceAll(",", "."),
          id: creatorId,
        }).toString(),
      });
    }
  };

  const handleBuyTip = async (tip: string) => {
    if (!tip) return;
    if (userNeedsAgeVerification()) {
      setOverrideRedirect(
        `${window.location.origin}/checkout?${createSearchParams({
          type: OrderType.Tip,
          amount: tip?.toString().replaceAll(",", "."),
          id: creatorId,
        }).toString()}`,
      );
      setIsAgeVerificationPopupOpen(true);
      return;
    }

    const body: CheckoutTipDto = {
      netAmount: parseFloat(tip?.toString().replaceAll(",", ".")),
      creatorId: creatorId,
      address: { country: user.address.country },
      paymentMethod: {
        paymentMethod: user.storedPaymentInformation[0].paymentMethod,
        referenceId: user.storedPaymentInformation[0].referenceUUID,
      },
      // @ts-expect-error 3DS typing is not correct
      threeDSBrowserData: getThreeDSBrowserData(),
    };
    await checkoutTip(body)
      .then((r) => {
        capture(CheckoutPosthogEvents.TipPaymentStart);
        window.location.replace(r.redirectUrl);
      })
      .catch((e) => {
        capture(CheckoutPosthogEvents.TipPaymentError, {
          error: e.message,
        });
        throw e;
      });
  };

  const handleBuyTipMutation = useMutation(handleBuyTip);

  useEffect(() => {
    if (open) capture(CheckoutPosthogEvents.TipPopupOpen);
  }, [open]);

  return (
    <>
      <PopupBottom
        open={open}
        setOpen={setOpen}
        className={className}
        data-testid={dataTestId}
        title={t("tips.send") as string}
      >
        <div className="mb-4 text-2xl font-semibold sm:hidden">{t("tips.send")}</div>
        <Formik
          initialValues={{
            tip: "",
          }}
          validationSchema={Yup.object().shape({
            tip: Yup.number()
              .transform((_, val) => +val.toString().replaceAll(",", "."))
              .typeError(t("validation.onlyNumbers") as string)
              .min(1, ({ min }) => t("validation.minValue", { minValue: min }))
              .max(9999, ({ max }) => t("validation.maxValue", { maxValue: max }))
              .test("is-decimal", t("validation.only2Decimals") as string, (val) => {
                if (val !== undefined) {
                  return /^\d+(\.\d{0,2})?$/.test(val.toString());
                }
                return true;
              })
              .required(t("validation.required") as string),
          })}
          onSubmit={() => {}}
        >
          {({ isValid, values }) => (
            <Form className="mt-4 w-full">
              <FormikPriceInput
                name="tip"
                label={t("tips.chooseAmount") as string}
                placeholder={t("tips.placeholder") as string}
                additionalInformation={
                  t("tips.chooseAmountBetween", {
                    min: displayCurrency(1),
                    max: displayCurrency(9999),
                  }) as string
                }
              />
              <InputInfo tip={values.tip} user={user} creatorId={creatorId} storedCreditCard={storedCreditCard} />
              {storedCreditCard ? (
                <>
                  <Button
                    type="submit"
                    text={
                      handleBuyTipMutation.isLoading
                        ? undefined
                        : t("tips.sendTipWith", { hint: user.storedPaymentInformation[0]?.hint })
                    }
                    IconFront={
                      handleBuyTipMutation.isLoading
                        ? () => <AiOutlineLoading3Quarters className="my-0.5 h-5 w-5 animate-spin" />
                        : undefined
                    }
                    className="mt-4"
                    onClick={() => handleBuyTipMutation.mutate(values.tip)}
                    disabled={!isValid || !values.tip || handleBuyTipMutation.isLoading}
                  />
                  <TernaryButton
                    type="submit"
                    className="mt-2 w-full text-sm"
                    onClick={() => handleTipCheckout(values.tip)}
                    disabled={!isValid}
                  >
                    <div className="w-full text-center">{t("tips.chooseDifferentPaymentMethod")}</div>
                  </TernaryButton>
                </>
              ) : (
                <Button
                  type="submit"
                  onClick={() => handleTipCheckout(values.tip)}
                  text={t("tips.send")}
                  className="mt-4"
                  disabled={!isValid}
                />
              )}
            </Form>
          )}
        </Formik>
      </PopupBottom>
      <AgeVerificationPopup
        open={ageVerificationPopupOpen}
        setOpen={setIsAgeVerificationPopupOpen}
        overrideRedirect={overrideRedirect}
      />
    </>
  );
};

const InputInfo = ({
  tip,
  user,
  creatorId,
  storedCreditCard,
}: {
  tip?: string;
  user: PrivateUserDto;
  creatorId: string;
  storedCreditCard: StoredPaymentInformationDto | undefined;
}) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  const { price } = useCheckoutItem(
    creatorId,
    "TIP",
    parseFloat(tip || "0"),
    user.address.country,
    storedCreditCard?.paymentMethod,
  );

  return (
    <>
      {tip && tip !== "" && storedCreditCard ? (
        <>
          {price ? (
            <div className="mt-3">
              {price.paymentFeeAmount > 0 && (
                <div className="flex w-full justify-between text-xs text-gray-400 xs:text-sm">
                  <span>{t("checkout.serviceFee")}</span>
                  <span>{displayCurrency(price.paymentFeeAmount)}</span>
                </div>
              )}
              <div className="flex w-full justify-between text-xs text-gray-400 xs:text-sm">
                <span>{t("checkout.VAT")}</span>
                <span>{displayCurrency(price.vatAmount)}</span>
              </div>
              <div className="mt-0.5 flex w-full justify-between text-xl font-semibold">
                <span>{t("checkout.total")}</span>
                <span>{displayCurrency(price.gross)}</span>
              </div>
            </div>
          ) : (
            <div className="mt-3">
              <div className="mt-1 flex w-full justify-between">
                <span className="h-4 w-20 rounded-md bg-gray-100" />
                <span className="h-4 w-20 rounded-md bg-gray-100" />
              </div>
              <div className="mt-1 flex w-full justify-between">
                <span className="h-4 w-20 rounded-md bg-gray-100" />
                <span className="h-4 w-20 rounded-md bg-gray-100" />
              </div>
              <div className="mt-1 flex w-full justify-between">
                <span className="h-6 w-20 rounded-md bg-gray-100" />
                <span className="h-6 w-24 rounded-md bg-gray-100" />
              </div>
            </div>
          )}
        </>
      ) : (
        <p className="mt-3 text-left text-xs text-gray-500">*{t("tips.additionalVat")}</p>
      )}
    </>
  );
};

export default TipPopupBottom;
