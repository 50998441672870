import { DisplayableMediaDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Media from "components/media/Media";
import { BaseComponent } from "types/components/component.type";

type FolderThumbnailsProps = {
  thumbnails: DisplayableMediaDto[];
} & BaseComponent;

const FolderThumbnails = ({ thumbnails, className, "data-testid": dataTestId }: FolderThumbnailsProps) => {
  return (
    <div className={classNames("flex items-center -space-x-5", className)} data-testid={dataTestId}>
      {thumbnails.map((thumbnail, idx) => (
        <div
          className="h-10 w-10 overflow-hidden rounded-md border border-white shadow-sm"
          style={{ zIndex: 5 - idx }}
          key={thumbnail.url}
        >
          <Media media={thumbnail} thumbnail={thumbnail} allowFullScreen={false} />
        </div>
      ))}
    </div>
  );
};

export default FolderThumbnails;
