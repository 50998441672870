import { useContext } from "react";
import MaloumClientContext from "contexts/MaloumClientContext";
import {
  CheckoutProductDto,
  CheckoutSubscriptionDto,
  CheckoutChatProductDto,
  CheckoutTipDto,
} from "@neolime-gmbh/api-gateway-client";

export const useCheckout = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const getPosthogCheckoutId = () => {
    const posthogCheckoutId = localStorage.getItem("posthog-checkout-id");
    return posthogCheckoutId || undefined;
  };

  const checkoutTip = async (body: CheckoutTipDto) => {
    const posthogCheckoutId = getPosthogCheckoutId();
    return maloumClient.checkout.checkoutTip(body, posthogCheckoutId || undefined);
  };

  const checkoutProduct = async (body: CheckoutProductDto) => {
    const posthogCheckoutId = getPosthogCheckoutId();
    return maloumClient.checkout.checkoutProduct(body, posthogCheckoutId || undefined);
  };

  const checkoutSubscription = async (body: CheckoutSubscriptionDto) => {
    const posthogCheckoutId = getPosthogCheckoutId();
    return maloumClient.checkout.checkoutSubscription(body, posthogCheckoutId || undefined);
  };

  const checkoutChatProduct = async (body: CheckoutChatProductDto) => {
    const posthogCheckoutId = getPosthogCheckoutId();
    return maloumClient.checkout.checkoutChatProduct(body, posthogCheckoutId || undefined);
  };

  return {
    checkoutTip,
    checkoutProduct,
    checkoutSubscription,
    checkoutChatProduct,
  };
};
