import { createContext, useContext } from "react";

interface ChatTabContextType {
  switchToPrimaryTab: () => void;
}

export const ChatTabContext = createContext<ChatTabContextType>({
  switchToPrimaryTab: () => {},
});

export const useChatTabContext = () => useContext(ChatTabContext);
