import classNames from "classnames";
import SelectChatIllustration from "components/atoms/illustration/SelectChatIllustration";
import { useTranslation } from "react-i18next";
import { BaseComponent } from "types/components/component.type";

const EmptyChatSelection = ({ className }: BaseComponent) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        "flex h-full min-h-screen w-full grow flex-col items-center justify-center gap-6",
        className,
      )}
    >
      <SelectChatIllustration />
      <div className="mx-20 text-center text-sm text-gray-500">{t("chat.noSelectionDescription")}</div>
    </div>
  );
};

export default EmptyChatSelection;
