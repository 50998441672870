import classNames from "classnames";
import { isMobileDevice } from "helper/deviceHelpers";
import { KeyboardEvent, useCallback, useEffect, useRef, forwardRef, useImperativeHandle, useState } from "react";
import { useParams } from "react-router-dom";
// TODO: Rework TextArea and AutoGrowTextarea

type Props = {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  className?: string;
  maxLength?: number;
  autogrow?: boolean;
  maxRows?: number;
  placeholder?: string | null;
  disabled?: boolean;
  "data-testid"?: string;
};

export type AutoGrowTextareaRef = {
  focus: () => void;
};

const AutoGrowTextarea = forwardRef<AutoGrowTextareaRef, Props>(
  (
    {
      className,
      maxLength,
      autogrow = true,
      value,
      onChange,
      maxRows = 5,
      onSubmit,
      placeholder,
      disabled = false,
      "data-testid": dataTestId,
    },
    ref,
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const { id } = useParams();

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (!isMobileDevice() && textAreaRef.current) {
          setTimeout(() => textAreaRef.current?.focus(), 300);
        }
      },
    }));

    const maxHeight = maxRows * 26;

    useEffect(() => {
      if (autogrow && textAreaRef.current) {
        textAreaRef.current.style.height = "32px";
        textAreaRef.current.style.maxHeight = `${maxHeight}px`;

        const scrollHeight = textAreaRef.current.scrollHeight;

        textAreaRef.current.style.height = scrollHeight + "px";
        if (!isMobileDevice()) textAreaRef.current.focus();
      }
    }, [textAreaRef, value, id]);

    // TODO: keyCode is deprecated according to sonarlint -> adjust
    // call submit on Enter & not Shift press
    const handleEnterToSubmit = useCallback(
      (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (!e.shiftKey && e.keyCode === 13) {
          onSubmit();
          e.preventDefault();
        }
      },
      [onSubmit],
    );

    return (
      <textarea
        className={classNames(className, "resize-none overflow-y-auto transition-none")}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        rows={maxRows}
        maxLength={maxLength}
        onKeyDown={handleEnterToSubmit}
        ref={textAreaRef}
        placeholder={placeholder !== null ? placeholder : ""}
        disabled={disabled}
        data-testid={dataTestId}
      />
    );
  },
);

AutoGrowTextarea.displayName = "AutoGrowTextarea";

export default AutoGrowTextarea;
