import { useContext, useRef } from "react";
import useMessagesStore from "state/messagesState";
import { DeleteMessageDto, MessageDto, SendMessageDto } from "@neolime-gmbh/api-gateway-client";
import SocketContext from "contexts/SocketContext";

const useSendMessage = (chatId: string) => {
  const { removeMessageFromChat, setMessagesForChat } = useMessagesStore();
  const messages = useMessagesStore((state) => state.messages[chatId] || []);
  const messagesRef = useRef<MessageDto[]>([]);
  messagesRef.current = messages;
  const socket = useContext(SocketContext);

  const emitSendMessageEvent = (message: SendMessageDto) => {
    socket?.emit("send_message", {
      chat: message.chat,
      content: message.content,
    });
  };

  const deleteMessage = (messageId: string, deleteTextOnly: boolean, deleteBroadcastMessage?: boolean) => {
    if (deleteTextOnly) {
      const messagesTmp = messagesRef.current;
      const msgIndex = messagesTmp.findIndex((m) => m._id === messageId);
      // @ts-expect-error fix this
      messagesTmp[msgIndex].content.text = undefined;

      setMessagesForChat(chatId, messagesTmp);
    } else {
      removeMessageFromChat(messageId, chatId);
    }
    socket?.emit("delete_message", {
      chat: chatId,
      message: messageId,
      deleteTextOnly,
      deleteBroadcastMessage,
    } as DeleteMessageDto);
  };

  const sendTextMessage = (message: string) => {
    emitSendMessageEvent({
      chat: chatId,
      content: {
        type: "text",
        text: message,
      },
    });
  };

  const sendMediaMessage = (mediaIds: string[], message: string | undefined) => {
    emitSendMessageEvent({
      chat: chatId,
      content: {
        type: "media",
        text: message,
        mediaIds: mediaIds,
      },
    });
  };

  const sendChatProduct = async (mediaIds: string[], netAmount: number, message?: string) => {
    emitSendMessageEvent({
      chat: chatId,
      content: {
        type: "chat_product",
        mediaIds: mediaIds,
        priceNet: netAmount,
        ...(message !== undefined && { text: message }),
      },
    });
  };

  return { sendTextMessage, sendMediaMessage, sendChatProduct, deleteMessage };
};

export default useSendMessage;
