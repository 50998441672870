import classNames from "classnames";
import HeaderBar from "components/layouts/HeaderBar";
import ChatUserCard from "../../components/molecules/ChatUserCard";
import Container from "../layouts/Container";
import Layout from "../layouts/Layout";
import {
  HiChatBubbleOvalLeft,
  HiEllipsisHorizontal,
  HiInformationCircle,
  HiOutlineInformationCircle,
} from "react-icons/hi2";
import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import useUserStore from "state/userState";
import MyTab from "components/basics/MyTab";
import { Tab } from "@headlessui/react";

type Props = {
  showHeader?: boolean;
};

const LoadingChat = ({ showHeader = true }: Props) => {
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const createLoadingItemComponent = (key: number) => {
    const right = key % 2 === 0;
    return (
      <div className={classNames("mb-2 flex w-full items-center")} key={key}>
        {right && <div className="grow" />}
        <div
          className={classNames("h-12 w-1/3 animate-pulse rounded-t-[30px]", {
            "rounded-l-[30px] bg-beige-400": right,
            "rounded-r-[30px] bg-gray-100": !right,
          })}
        />
      </div>
    );
  };

  if (!showHeader) {
    return (
      <div className="grow px-4 pt-6" data-testid={"loading-chat"}>
        {Array(5)
          .fill(null)
          .map((_, key) => createLoadingItemComponent(key))}
      </div>
    );
  }

  return (
    <Layout hasNavigation={false} hideNavigationMobile hideVerificationButton growFullHeight>
      <Container className="flex h-full max-h-screen flex-col md:max-w-chat" hasPadding={false}>
        <Tab.Group>
          <HeaderBar className="md:max-w-chat" alwaysShowDivider>
            <HeaderBar.SubPage className="sm:!pt-4">
              <HeaderBar.Left className="flex basis-2/12 items-center">
                <HeaderBar.BackButton />
              </HeaderBar.Left>
              <HeaderBar.Center className="flex basis-8/12 items-center">
                <ChatUserCard />
              </HeaderBar.Center>
              <HeaderBar.Right className="flex basis-2/12 items-center">
                <HeaderBar.Button onClick={() => {}}>
                  <HiEllipsisHorizontal className="h-6 w-6" />
                </HeaderBar.Button>
              </HeaderBar.Right>
            </HeaderBar.SubPage>
            {user?.isCreator && (
              <Tab.List className="flex chatBreakpoint:hidden">
                <MyTab
                  isLoading
                  defaultIcon={<HiChatBubbleOvalLeft className="mr-1 h-5 w-5" />}
                  activeIcon={<HiChatBubbleOvalLeft className="mr-1 h-5 w-5" />}
                />
                <MyTab
                  isLoading
                  defaultIcon={<HiOutlineInformationCircle className="mr-1 h-5 w-5" />}
                  activeIcon={<HiInformationCircle className="mr-1 h-5 w-5" />}
                />
              </Tab.List>
            )}
          </HeaderBar>
        </Tab.Group>
        <Container className="bg-white md:max-w-chat" hasPadding={false}>
          <div className="relative flex-1 overflow-hidden">
            <div className="absolute inset-0 flex flex-col px-4">
              <div className="grow px-4 pt-6" data-testid={"loading-chat"}>
                {Array(5)
                  .fill(null)
                  .map((_, key) => createLoadingItemComponent(key))}
              </div>
              <div className="sticky bottom-0 mt-2 bg-white px-4 pb-8">
                <div className="h-10 animate-pulse rounded-md bg-gray-100"></div>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </Layout>
  );
};

export default LoadingChat;
