import classNames from "classnames";
import useCurrency from "hooks/useCurrency.hook";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineBanknotes } from "react-icons/hi2";
import useUserStore from "state/userState";
import { MessagePriceDto } from "@neolime-gmbh/api-gateway-client";

type Props = {
  price?: MessagePriceDto;
  senderId: string;
};

const TipMessage = ({ price, senderId }: Props) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();
  const user = useUserStore((state) => state.user);

  const isSender = useMemo(() => user._id === senderId, [senderId, user]);

  return (
    <div className={classNames("flex flex-col gap-1 text-white", { "text-right": isSender })}>
      <span className={classNames("inline-flex items-center gap-1 text-xs", { "justify-end": isSender })}>
        <HiOutlineBanknotes className="h-4 w-4" />
        <span>{t("tips.name")}</span>
      </span>
      <span className={"text-2xl font-semibold"}>{price?.net && displayCurrency(price.net)}</span>
      {isSender && (
        <span data-testid="tipsYouPaid" className={"text-xs"}>
          {price?.gross && t("tips.youPaid", { amount: displayCurrency(price.gross) })}
        </span>
      )}
    </div>
  );
};

export default TipMessage;
