import { OndatoLanguages, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { usePostHog } from "posthog-js/react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useUserStore from "state/userState";

type Props = {
  overrideRedirect?: string;
};

export const useAgeVerification = ({ overrideRedirect }: Props = {}) => {
  const [loading, setLoading] = useState(false);
  const { maloumClient } = useContext(MaloumClientContext);
  const { i18n } = useTranslation();
  const location = useLocation();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const posthog = usePostHog();

  const onVerifyNow = async () => {
    try {
      setLoading(true);

      const redirectTo = overrideRedirect ?? `${window.location.origin}${location.pathname}${location.search}`;
      window.location.href = await maloumClient.verifications.startAgeVerification({
        language: i18n.language.startsWith("de") ? OndatoLanguages.DE_DE : OndatoLanguages.EN_GB,
        redirectTo,
      });
    } catch (e) {
      console.error("Verification failed", e);
      setLoading(false);
    }
  };

  const userNeedsAgeVerification = () => {
    const ageEstimationEnabled = posthog?.getFeatureFlag("fave") ?? false;
    return !user.isLegalAgeVerified && !user.isVerified && !user.isTrusted && ageEstimationEnabled;
  };

  return { onVerifyNow, userNeedsAgeVerification, loading };
};
