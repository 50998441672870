import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext, useMemo } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";

const DEFAULT_STALE_TIME = 1000 * 60 * 10; // 10 minutes

const useTopCreators = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchNextTopCreators = async (query: QueryFunctionContext<string[], any>) =>
    await maloumClient.users.getTopCreators(query?.pageParam);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["top-creators"],
    fetchNextTopCreators,
    {
      getNextPageParam: (lastPage) => lastPage.next,
      staleTime: DEFAULT_STALE_TIME,
    },
  );

  const topCreators = useMemo(() => {
    if (!data) return [];
    return data.pages.flatMap((p) => p.data);
  }, [data]);

  return { topCreators, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage };
};

export default useTopCreators;
