import { BaseComponent } from "types/components/component.type";

type Props = BaseComponent;

const SelectChatIllustration = ({ className, "data-testid": dataTestId }: Props) => {
  return (
    <svg
      width="190"
      height="150"
      viewBox="0 0 190 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid={dataTestId}
    >
      <path
        d="M152.092 35.5963H151.269V13.0495C151.269 11.3358 150.931 9.6389 150.275 8.05567C149.619 6.47243 148.657 5.03387 147.445 3.82211C146.233 2.61035 144.794 1.64913 143.21 0.993335C141.626 0.337536 139.929 8.10897e-07 138.214 0H90.4276C86.9653 -7.6422e-06 83.6448 1.37484 81.1966 3.82208C78.7484 6.26932 77.3731 9.5885 77.373 13.0494V136.743C77.373 140.204 78.7484 143.523 81.1966 145.97C83.6448 148.418 86.9653 149.792 90.4275 149.792H138.214C141.677 149.792 144.997 148.418 147.445 145.97C149.893 143.523 151.269 140.204 151.269 136.743V51.6455H152.092L152.092 35.5963Z"
        fill="#E6E6E6"
      />
      <path
        d="M148.489 13.1402V136.652C148.489 139.236 147.462 141.715 145.634 143.543C143.805 145.37 141.326 146.397 138.74 146.397H90.724C88.1383 146.397 85.6586 145.37 83.8302 143.543C82.0018 141.715 80.9747 139.236 80.9746 136.652V13.1402C80.9747 10.5555 82.0018 8.07667 83.8302 6.24902C85.6586 4.42137 88.1383 3.39458 90.724 3.39453H96.5489C96.2627 4.09761 96.1537 4.86023 96.2315 5.6153C96.3093 6.37037 96.5716 7.09476 96.9952 7.72474C97.4188 8.35472 97.9908 8.871 98.6608 9.22815C99.3309 9.5853 100.078 9.77238 100.838 9.77294H128.214C128.974 9.77238 129.721 9.58529 130.391 9.22814C131.061 8.87099 131.633 8.35471 132.057 7.72473C132.481 7.09475 132.743 6.37037 132.821 5.6153C132.899 4.86023 132.79 4.09761 132.503 3.39453H138.74C141.326 3.39458 143.805 4.42137 145.634 6.24902C147.462 8.07667 148.489 10.5555 148.489 13.1402Z"
        fill="white"
      />
      <path
        d="M93.2842 14.8516C91.5367 14.8516 89.8608 15.5455 88.6252 16.7806C87.3895 18.0158 86.6953 19.691 86.6953 21.4378C86.6953 23.1846 87.3895 24.8599 88.6251 26.095C89.8608 27.3302 91.5366 28.0241 93.2841 28.0241C95.0316 28.0241 96.7075 27.3302 97.9432 26.0951C99.1788 24.8599 99.873 23.1846 99.873 21.4379C99.873 19.6911 99.1788 18.0158 97.9432 16.7806C96.7075 15.5455 95.0316 14.8516 93.2842 14.8516Z"
        fill="#E6E6E6"
      />
      <path
        d="M141.123 26.9924H106.532C106.095 26.9924 105.676 26.8189 105.367 26.5101C105.058 26.2013 104.885 25.7825 104.885 25.3458C104.885 24.9091 105.058 24.4903 105.367 24.1815C105.676 23.8727 106.095 23.6992 106.532 23.6992H141.123C141.56 23.6992 141.979 23.8727 142.288 24.1815C142.597 24.4903 142.77 24.9091 142.77 25.3458C142.77 25.7825 142.597 26.2013 142.288 26.5101C141.979 26.8189 141.56 26.9924 141.123 26.9924Z"
        fill="#E6E6E6"
      />
      <path
        d="M117.445 19.9963H106.532C106.095 19.9963 105.676 19.8228 105.367 19.514C105.058 19.2052 104.885 18.7864 104.885 18.3497C104.885 17.913 105.058 17.4942 105.367 17.1854C105.676 16.8766 106.095 16.7031 106.532 16.7031H117.445C117.882 16.7031 118.301 16.8766 118.609 17.1854C118.918 17.4942 119.092 17.913 119.092 18.3497C119.092 18.7864 118.918 19.2052 118.609 19.514C118.301 19.8228 117.882 19.9963 117.445 19.9963Z"
        fill="#E6E6E6"
      />
      <path
        d="M93.2842 42.4297C91.5367 42.4297 89.8608 43.1236 88.6252 44.3587C87.3895 45.5939 86.6953 47.2692 86.6953 49.016C86.6953 50.7627 87.3895 52.438 88.6251 53.6732C89.8608 54.9083 91.5366 55.6023 93.2841 55.6023C95.0316 55.6023 96.7075 54.9084 97.9432 53.6732C99.1788 52.438 99.873 50.7628 99.873 49.016C99.873 47.2692 99.1788 45.5939 97.9432 44.3588C96.7075 43.1236 95.0316 42.4297 93.2842 42.4297Z"
        fill="#E6E6E6"
      />
      <path
        d="M141.123 54.5744H106.532C106.095 54.5744 105.676 54.4009 105.367 54.0921C105.058 53.7833 104.885 53.3645 104.885 52.9278C104.885 52.4911 105.058 52.0723 105.367 51.7635C105.676 51.4547 106.095 51.2812 106.532 51.2812H141.123C141.56 51.2812 141.979 51.4547 142.288 51.7635C142.597 52.0723 142.77 52.4911 142.77 52.9278C142.77 53.3645 142.597 53.7833 142.288 54.0921C141.979 54.4009 141.56 54.5744 141.123 54.5744Z"
        fill="#E6E6E6"
      />
      <path
        d="M117.445 47.5744H106.532C106.095 47.5744 105.676 47.4009 105.367 47.0921C105.058 46.7833 104.885 46.3645 104.885 45.9278C104.885 45.4911 105.058 45.0723 105.367 44.7635C105.676 44.4547 106.095 44.2812 106.532 44.2812H117.445C117.882 44.2812 118.301 44.4547 118.609 44.7635C118.918 45.0723 119.092 45.4911 119.092 45.9278C119.092 46.3645 118.918 46.7833 118.609 47.0921C118.301 47.4009 117.882 47.5744 117.445 47.5744Z"
        fill="#E6E6E6"
      />
      <path
        d="M93.2842 97.5898C91.5367 97.5898 89.8608 98.2837 88.6252 99.5189C87.3895 100.754 86.6953 102.429 86.6953 104.176C86.6953 105.923 87.3895 107.598 88.6251 108.833C89.8608 110.068 91.5366 110.762 93.2841 110.762C95.0316 110.762 96.7075 110.069 97.9432 108.833C99.1788 107.598 99.873 105.923 99.873 104.176C99.873 102.429 99.1788 100.754 97.9432 99.5189C96.7075 98.2837 95.0316 97.5898 93.2842 97.5898Z"
        fill="#E6E6E6"
      />
      <path
        d="M141.123 109.735H106.532C106.095 109.735 105.676 109.561 105.367 109.252C105.058 108.943 104.885 108.525 104.885 108.088C104.885 107.651 105.058 107.232 105.367 106.924C105.676 106.615 106.095 106.441 106.532 106.441H141.123C141.56 106.441 141.979 106.615 142.288 106.924C142.597 107.232 142.77 107.651 142.77 108.088C142.77 108.525 142.597 108.943 142.288 109.252C141.979 109.561 141.56 109.735 141.123 109.735Z"
        fill="#E6E6E6"
      />
      <path
        d="M117.445 102.735H106.532C106.095 102.735 105.676 102.561 105.367 102.252C105.058 101.943 104.885 101.525 104.885 101.088C104.885 100.651 105.058 100.232 105.367 99.9237C105.676 99.6149 106.095 99.4414 106.532 99.4414H117.445C117.882 99.4414 118.301 99.6149 118.609 99.9237C118.918 100.232 119.092 100.651 119.092 101.088C119.092 101.525 118.918 101.943 118.609 102.252C118.301 102.561 117.882 102.735 117.445 102.735Z"
        fill="#E6E6E6"
      />
      <path
        d="M93.2842 125.172C91.5367 125.172 89.8608 125.866 88.6252 127.101C87.3895 128.336 86.6953 130.011 86.6953 131.758C86.6953 133.505 87.3895 135.18 88.6251 136.415C89.8608 137.651 91.5366 138.344 93.2841 138.344C95.0316 138.344 96.7075 137.651 97.9432 136.415C99.1788 135.18 99.873 133.505 99.873 131.758C99.873 130.011 99.1788 128.336 97.9432 127.101C96.7075 125.866 95.0316 125.172 93.2842 125.172Z"
        fill="#E6E6E6"
      />
      <path
        d="M141.123 137.313H106.532C106.095 137.313 105.676 137.139 105.367 136.83C105.058 136.522 104.885 136.103 104.885 135.666C104.885 135.229 105.058 134.811 105.367 134.502C105.676 134.193 106.095 134.02 106.532 134.02H141.123C141.56 134.02 141.979 134.193 142.288 134.502C142.597 134.811 142.77 135.229 142.77 135.666C142.77 136.103 142.597 136.522 142.288 136.83C141.979 137.139 141.56 137.313 141.123 137.313Z"
        fill="#E6E6E6"
      />
      <path
        d="M117.445 130.317H106.532C106.095 130.317 105.676 130.143 105.367 129.834C105.058 129.526 104.885 129.107 104.885 128.67C104.885 128.233 105.058 127.814 105.367 127.506C105.676 127.197 106.095 127.023 106.532 127.023H117.445C117.882 127.023 118.301 127.197 118.609 127.506C118.918 127.814 119.092 128.233 119.092 128.67C119.092 129.107 118.918 129.526 118.609 129.834C118.301 130.143 117.882 130.317 117.445 130.317Z"
        fill="#E6E6E6"
      />
      <path
        d="M154.471 92.4468H74.9931C74.0923 92.4458 73.2288 92.0877 72.5919 91.451C71.955 90.8143 71.5967 89.9511 71.5957 89.0507V63.5289C71.5967 62.6285 71.955 61.7653 72.5919 61.1286C73.2288 60.492 74.0923 60.1338 74.9931 60.1328H154.471C155.371 60.1338 156.235 60.492 156.872 61.1286C157.509 61.7653 157.867 62.6285 157.868 63.5289V89.0507C157.867 89.9511 157.509 90.8143 156.872 91.451C156.235 92.0877 155.371 92.4458 154.471 92.4468Z"
        fill="white"
      />
      <path
        d="M84.7529 85.6978C82.8908 85.6978 81.0705 85.1459 79.5222 84.1117C77.9739 83.0776 76.7672 81.6077 76.0546 79.888C75.342 78.1683 75.1555 76.276 75.5188 74.4503C75.8821 72.6247 76.7788 70.9477 78.0955 69.6315C79.4122 68.3153 81.0898 67.419 82.9161 67.0558C84.7425 66.6927 86.6355 66.8791 88.3559 67.5914C90.0763 68.3037 91.5467 69.51 92.5812 71.0577C93.6158 72.6054 94.168 74.425 94.168 76.2864C94.1651 78.7816 93.1723 81.1738 91.4072 82.9382C89.6422 84.7025 87.2491 85.695 84.7529 85.6978ZM84.7529 67.2866C82.9723 67.2866 81.2316 67.8145 79.751 68.8034C78.2704 69.7923 77.1165 71.1979 76.435 72.8424C75.7536 74.4868 75.5753 76.2964 75.9227 78.0422C76.2701 79.788 77.1276 81.3916 78.3867 82.6502C79.6458 83.9089 81.25 84.766 82.9965 85.1133C84.7429 85.4605 86.5532 85.2823 88.1983 84.6011C89.8434 83.9199 91.2495 82.7664 92.2388 81.2864C93.2281 79.8064 93.7562 78.0664 93.7562 76.2864C93.7535 73.9004 92.804 71.6128 91.1162 69.9256C89.4283 68.2384 87.1399 67.2893 84.7529 67.2866Z"
        fill="#3F3D56"
      />
      <path
        d="M151.617 84.0559H103.269C102.967 84.0562 102.667 83.9969 102.388 83.8814C102.108 83.7658 101.854 83.5964 101.64 83.3826C101.426 83.1689 101.256 82.9151 101.14 82.6357C101.024 82.3564 100.965 82.0569 100.965 81.7545C100.965 81.4521 101.024 81.1527 101.14 80.8733C101.256 80.594 101.426 80.3402 101.64 80.1264C101.854 79.9127 102.108 79.7432 102.388 79.6277C102.667 79.5122 102.967 79.4528 103.269 79.4531H151.617C151.92 79.4528 152.219 79.5122 152.499 79.6277C152.779 79.7432 153.033 79.9127 153.247 80.1264C153.461 80.3402 153.631 80.594 153.746 80.8733C153.862 81.1527 153.922 81.4521 153.922 81.7545C153.922 82.0569 153.862 82.3564 153.746 82.6357C153.631 82.9151 153.461 83.1689 153.247 83.3826C153.033 83.5964 152.779 83.7658 152.499 83.8814C152.219 83.9969 151.92 84.0562 151.617 84.0559Z"
        fill="#F33800"
      />
      <path
        d="M118.522 74.2747H103.269C102.967 74.275 102.667 74.2157 102.388 74.1001C102.108 73.9846 101.854 73.8151 101.64 73.6014C101.426 73.3877 101.256 73.1339 101.14 72.8545C101.024 72.5751 100.965 72.2757 100.965 71.9733C100.965 71.6709 101.024 71.3714 101.14 71.0921C101.256 70.8127 101.426 70.5589 101.64 70.3452C101.854 70.1314 102.108 69.962 102.388 69.8464C102.667 69.7309 102.967 69.6716 103.269 69.6719H118.522C118.824 69.6716 119.124 69.7309 119.404 69.8464C119.683 69.962 119.937 70.1314 120.151 70.3452C120.365 70.5589 120.535 70.8127 120.651 71.0921C120.767 71.3714 120.826 71.6709 120.826 71.9733C120.826 72.2757 120.767 72.5751 120.651 72.8545C120.535 73.1339 120.365 73.3877 120.151 73.6014C119.937 73.8151 119.683 73.9846 119.404 74.1001C119.124 74.2157 118.824 74.275 118.522 74.2747Z"
        fill="#F33800"
      />
      <path
        d="M154.471 92.4468H74.9931C74.0923 92.4458 73.2288 92.0877 72.5919 91.451C71.955 90.8143 71.5967 89.9511 71.5957 89.0507V63.5289C71.5967 62.6285 71.955 61.7653 72.5919 61.1286C73.2288 60.492 74.0923 60.1338 74.9931 60.1328H154.471C155.371 60.1338 156.235 60.492 156.872 61.1286C157.509 61.7653 157.867 62.6285 157.868 63.5289V89.0507C157.867 89.9511 157.509 90.8143 156.872 91.451C156.235 92.0877 155.371 92.4458 154.471 92.4468ZM74.9931 60.3386C74.1469 60.3396 73.3357 60.676 72.7374 61.2741C72.1391 61.8722 71.8026 62.6831 71.8016 63.5289V89.0507C71.8026 89.8966 72.1391 90.7074 72.7374 91.3055C73.3357 91.9036 74.1469 92.24 74.9931 92.241H154.471C155.317 92.24 156.128 91.9036 156.726 91.3055C157.325 90.7074 157.661 89.8966 157.662 89.0507V63.5289C157.661 62.6831 157.325 61.8722 156.726 61.2741C156.128 60.676 155.317 60.3396 154.471 60.3386H74.9931Z"
        fill="#3F3D56"
      />
      <path
        d="M84.587 80.8664C86.7083 80.8664 88.4279 79.1474 88.4279 77.0269C88.4279 74.9065 86.7083 73.1875 84.587 73.1875C82.4657 73.1875 80.7461 74.9065 80.7461 77.0269C80.7461 79.1474 82.4657 80.8664 84.587 80.8664Z"
        fill="#2F2E41"
      />
      <path
        d="M81.5889 74.9601C82.2096 74.3397 82.3354 73.4595 81.8698 72.9942C81.4043 72.5289 80.5238 72.6546 79.9031 73.275C79.2825 73.8955 79.1567 74.7757 79.6222 75.241C80.0877 75.7063 80.9682 75.5806 81.5889 74.9601Z"
        fill="#2F2E41"
      />
      <path
        d="M88.5906 73.7384C88.8492 73.1332 88.4045 72.3632 87.5973 72.0185C86.7901 71.6738 85.9261 71.885 85.6675 72.4902C85.4089 73.0953 85.8536 73.8654 86.6608 74.21C87.468 74.5547 88.332 74.3435 88.5906 73.7384Z"
        fill="#2F2E41"
      />
      <path
        d="M84.709 81.2045C86.5093 81.2045 87.9687 79.7456 87.9687 77.946C87.9687 76.1464 86.5093 74.6875 84.709 74.6875C82.9087 74.6875 81.4492 76.1464 81.4492 77.946C81.4492 79.7456 82.9087 81.2045 84.709 81.2045Z"
        fill="#FFB8B8"
      />
      <path
        d="M81.2852 75.8972C82.0301 76.4105 82.9142 76.6836 83.8189 76.68C83.4962 76.9037 83.1288 77.0547 82.742 77.1228C83.9449 77.3807 85.188 77.3877 86.3937 77.1433C86.6617 77.1004 86.9203 77.0116 87.1581 76.8809C87.2767 76.8147 87.3799 76.7244 87.4612 76.6157C87.5425 76.507 87.6 76.3824 87.63 76.2501C87.7101 75.7926 87.3535 75.3769 86.9828 75.0968C86.4154 74.6744 85.7611 74.3834 85.0674 74.2449C84.3736 74.1063 83.6577 74.1236 82.9714 74.2955C82.5142 74.3875 82.0981 74.6225 81.7834 74.9666C81.4923 75.3263 81.4061 75.8809 81.6834 76.2513L81.2852 75.8972Z"
        fill="#2F2E41"
      />
      <path
        d="M36.5964 105.208C36.501 104.899 36.4746 104.573 36.5189 104.253C36.5633 103.933 36.6773 103.626 36.853 103.355C37.0287 103.083 37.2618 102.854 37.5358 102.682C37.8099 102.51 38.1182 102.401 38.4392 102.361L49.0087 90.0156L51.4739 92.603L40.946 104.441C40.9768 104.984 40.8071 105.52 40.4691 105.946C40.1311 106.373 39.6483 106.661 39.1121 106.755C38.576 106.85 38.0237 106.745 37.5601 106.459C37.0964 106.174 36.7535 105.729 36.5964 105.208Z"
        fill="#A0616A"
      />
      <path
        d="M45.5695 91.946C45.5587 91.8133 45.5766 91.6799 45.6221 91.5548C45.6676 91.4297 45.7396 91.3159 45.8331 91.2211L49.0984 87.9113C49.5002 87.3611 50.1038 86.9926 50.777 86.8867C51.4502 86.7809 52.1379 86.9462 52.6892 87.3465C53.2406 87.7469 53.6106 88.3495 53.718 89.0221C53.8254 89.6948 53.6616 90.3826 53.2624 90.9347L51.0912 95.0625C51.0292 95.1803 50.9426 95.2834 50.8372 95.3647C50.7318 95.446 50.6101 95.5037 50.4804 95.5337C50.3507 95.5638 50.216 95.5655 50.0855 95.5389C49.9551 95.5122 49.8319 95.4578 49.7244 95.3792L45.9463 92.6191C45.8387 92.5406 45.7494 92.4398 45.6843 92.3236C45.6193 92.2075 45.5801 92.0786 45.5695 91.946Z"
        fill="#F33800"
      />
      <path
        d="M53.2368 84.3682C56.0298 84.3682 58.294 82.1049 58.294 79.313C58.294 76.5211 56.0298 74.2578 53.2368 74.2578C50.4438 74.2578 48.1797 76.5211 48.1797 79.313C48.1797 82.1049 50.4438 84.3682 53.2368 84.3682Z"
        fill="#A0616A"
      />
      <path
        d="M50.488 101.937C50.3875 101.862 50.3034 101.767 50.2408 101.659C50.1783 101.55 50.1387 101.43 50.1244 101.306C49.7748 98.3843 49.266 95.4841 48.6003 92.6181C48.3055 91.3302 48.4886 89.9791 49.1154 88.816C49.7422 87.6528 50.7702 86.7568 52.0082 86.2943C53.2463 85.8318 54.6102 85.8343 55.8465 86.3014C57.0828 86.7685 58.1074 87.6684 58.7299 88.8339C60.7187 92.565 60.8706 97.6876 60.8436 99.7169C60.8406 99.9335 60.7615 100.142 60.6203 100.306C60.4791 100.471 60.2846 100.58 60.0709 100.616L51.1976 102.109C51.1459 102.118 51.0935 102.122 51.0411 102.122C50.8415 102.122 50.6473 102.057 50.488 101.937Z"
        fill="#2F2E41"
      />
      <path
        d="M69.6446 85.4758C69.6194 85.5868 69.6029 85.6997 69.5954 85.8133L60.7504 90.914L58.6005 89.6768L56.3086 92.6761L59.9016 95.236C60.1919 95.4428 60.5414 95.5499 60.8978 95.5413C61.2541 95.5326 61.5981 95.4086 61.8779 95.1879L70.9986 87.9953C71.4102 88.1476 71.8584 88.171 72.2837 88.0625C72.709 87.9539 73.0911 87.7185 73.3792 87.3875C73.6674 87.0566 73.8479 86.6458 73.8968 86.2098C73.9457 85.7738 73.8606 85.3332 73.6529 84.9467C73.4452 84.5602 73.1247 84.2461 72.7341 84.0461C72.3434 83.8461 71.9011 83.7698 71.466 83.8272C71.0308 83.8846 70.6235 84.0731 70.2982 84.3676C69.9728 84.6621 69.7449 85.0486 69.6446 85.4758Z"
        fill="#A0616A"
      />
      <path
        d="M60.4541 91.1552L57.5954 94.8588C57.5141 94.9641 57.411 95.0507 57.2932 95.1127C57.1753 95.1747 57.0455 95.2105 56.9126 95.2178C56.7796 95.2251 56.6466 95.2036 56.5227 95.1549C56.3988 95.1062 56.2869 95.0313 56.1945 94.9355L52.9583 91.5762C52.4214 91.1571 52.0726 90.5424 51.9885 89.8666C51.9043 89.1909 52.0916 88.5094 52.5093 87.9715C52.927 87.4336 53.541 87.0833 54.2168 86.9972C54.8925 86.9111 55.5748 87.0964 56.1141 87.5124L60.173 89.7812C60.2892 89.8462 60.39 89.9354 60.4686 90.0429C60.5471 90.1503 60.6016 90.2734 60.6283 90.4038C60.655 90.5342 60.6532 90.6689 60.6232 90.7985C60.5931 90.9282 60.5355 91.0498 60.4541 91.1552Z"
        fill="#F33800"
      />
      <path d="M64.9106 147.459L67.4349 147.459L68.0291 137.758L64.9102 137.727L64.9106 147.459Z" fill="#A0616A" />
      <path
        d="M64.2676 146.637L69.2388 146.637H69.239C69.655 146.637 70.067 146.719 70.4514 146.878C70.8358 147.037 71.185 147.27 71.4792 147.564C71.7734 147.858 72.0067 148.207 72.1659 148.592C72.3252 148.976 72.4071 149.388 72.4071 149.804V149.906L64.2677 149.907L64.2676 146.637Z"
        fill="#2F2E41"
      />
      <path d="M40.8207 147.46L43.3451 147.459L44.5459 137.727L40.8203 137.727L40.8207 147.46Z" fill="#A0616A" />
      <path
        d="M40.1758 146.637L45.147 146.637H45.1472C45.5632 146.637 45.9752 146.719 46.3596 146.878C46.744 147.037 47.0932 147.27 47.3874 147.564C47.6816 147.858 47.9149 148.207 48.0741 148.592C48.2334 148.976 48.3153 149.388 48.3153 149.804V149.906L40.1759 149.907L40.1758 146.637Z"
        fill="#2F2E41"
      />
      <path
        d="M43.5721 142.404L40.4394 141.996C40.3085 141.979 40.1827 141.934 40.0706 141.864C39.9584 141.795 39.8625 141.702 39.7891 141.592C39.7157 141.483 39.6666 141.359 39.6452 141.228C39.6237 141.098 39.6303 140.965 39.6645 140.837L44.2342 118.39C44.6885 116.211 45.0028 114.005 45.1753 111.786C45.3087 109.98 45.8375 107.426 47.5992 105.517C48.3156 104.734 48.9843 103.908 49.6015 103.044L50.9847 101.13L60.1992 99.6641L60.2056 99.778C60.6036 107.097 62.5055 114.256 65.7926 120.808C67.2713 123.755 68.12 126.978 68.2848 130.27L68.807 140.449C68.8135 140.578 68.793 140.707 68.7468 140.827C68.7006 140.948 68.6297 141.057 68.5387 141.149C68.448 141.241 68.3388 141.313 68.2183 141.36C68.0978 141.407 67.9687 141.428 67.8395 141.421L64.8447 141.285C64.6417 141.276 64.4474 141.2 64.2917 141.069C64.136 140.939 64.0275 140.761 63.9827 140.562L61.4823 129.411C61.4598 129.312 61.4166 129.218 61.3556 129.136L53.5086 118.678C53.4527 118.603 53.3775 118.545 53.2911 118.51C53.2047 118.475 53.1103 118.464 53.0182 118.478C52.926 118.492 52.8394 118.531 52.7677 118.591C52.6959 118.651 52.6418 118.729 52.6109 118.817L44.5665 141.792C44.5024 141.973 44.3838 142.13 44.227 142.241C44.0702 142.352 43.8829 142.412 43.6908 142.412C43.6511 142.412 43.6115 142.41 43.5721 142.404Z"
        fill="#2F2E41"
      />
      <path
        d="M47.6456 75.8065C48.0473 74.5892 48.3438 73.3397 48.5319 72.0718C48.5911 71.3336 48.4019 70.5393 47.8641 70.03C46.8576 69.0768 45.1477 69.5308 44.0951 70.4329C42.574 71.7366 41.8153 73.7402 41.5286 75.7225C41.2419 77.7049 41.3681 79.7207 41.299 81.7224C41.23 83.7242 40.9436 85.7825 39.9227 87.5059C38.9018 89.2294 37.0073 90.5523 35.0089 90.4056C34.2023 90.3621 33.4365 90.0376 32.8441 89.4886C32.5534 89.2121 32.3332 88.8699 32.202 88.4908C32.0709 88.1117 32.0325 87.7067 32.0903 87.3097C31.0689 89.1379 31.5867 91.6157 33.1101 93.0529C34.6336 94.4901 36.977 94.8964 38.9798 94.2828C40.9826 93.6692 42.6309 92.1305 43.6155 90.2823C44.6001 88.4341 44.9609 86.299 44.9087 84.2057C44.8685 82.5982 44.5951 80.9775 44.8669 79.3926C45.1386 77.8078 46.1309 76.1985 47.6956 75.8253L47.6456 75.8065Z"
        fill="#2F2E41"
      />
      <path
        d="M54.6708 77.8872C54.9376 78.7781 55.958 79.3274 56.8759 79.1767C57.7939 79.0261 58.5487 78.2704 58.8277 77.3833C59.0798 76.4773 58.9766 75.5092 58.539 74.6766C58.2979 74.1776 57.9607 73.731 57.5467 73.3624C57.1327 72.9938 56.6501 72.7104 56.1264 72.5285C55.5998 72.354 55.0389 72.3087 54.4911 72.3962C53.9432 72.4837 53.4244 72.7015 52.9784 73.0313C52.9309 72.8312 52.8309 72.6473 52.6887 72.4987C52.5465 72.35 52.3672 72.2419 52.1694 72.1856C51.7709 72.0782 51.3504 72.0844 50.9553 72.2035C49.8167 72.5278 48.8302 73.2452 48.171 74.2282C47.5202 75.2082 47.0897 76.3175 46.9091 77.4798C46.5985 79.2228 46.713 81.1274 47.6924 82.6026C48.6717 84.0778 50.6752 84.9489 52.3006 84.2456C52.402 83.7276 52.1619 83.2061 51.8841 82.7573C51.6063 82.3085 51.2771 81.8713 51.1704 81.3545C51.0637 80.8376 51.2682 80.2054 51.7684 80.0364C52.1951 79.8922 52.65 80.1258 53.0995 80.154C53.3548 80.17 53.6098 80.1187 53.8389 80.0051C54.0681 79.8915 54.2633 79.7196 54.4051 79.5068C54.5468 79.294 54.6301 79.0476 54.6465 78.7925C54.6629 78.5373 54.612 78.2823 54.4987 78.0531L54.6708 77.8872Z"
        fill="#2F2E41"
      />
      <path
        d="M189.223 150.001H0.205901C0.151292 150.001 0.0989206 149.98 0.0603067 149.941C0.0216929 149.903 0 149.85 0 149.796C0 149.741 0.0216929 149.689 0.0603067 149.65C0.0989206 149.612 0.151292 149.59 0.205901 149.59H189.223C189.277 149.59 189.33 149.612 189.368 149.65C189.407 149.689 189.429 149.741 189.429 149.796C189.429 149.85 189.407 149.903 189.368 149.941C189.33 149.98 189.277 150.001 189.223 150.001Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default SelectChatIllustration;
