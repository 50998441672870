import TernaryButton from "components/atoms/buttons/TernaryButton";
import UploadsCallout from "components/atoms/vault/UploadsCallout";
import HeaderBar from "components/layouts/HeaderBar";
import FolderList from "components/organisms/vault/FolderList";
import { useTranslation } from "react-i18next";

type Props = {
  selectedFolderId?: string;
  isSelectingMode: boolean;
  onFolderSelection: (folderId: string) => void;
  onCreateFolder: () => void;
};

const Folders = ({ selectedFolderId, isSelectingMode, onFolderSelection, onCreateFolder }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <HeaderBar alwaysShowDividerXL>
        <HeaderBar.SubPage>
          <HeaderBar.Left className="md:ml-0">
            <span className="hidden text-xl font-semibold md:block">{t("vault.folders")}</span>
            {isSelectingMode ? (
              <HeaderBar.Cancel className="md:-ml-2 md:hidden" />
            ) : (
              <HeaderBar.BackButton className="md:hidden" />
            )}
          </HeaderBar.Left>
          <HeaderBar.Center className="md:hidden">
            <HeaderBar.Title>{t("vault.vault")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right className="mr-0 min-w-fit md:-mr-4">
            <TernaryButton variant="red" size="sm" onClick={onCreateFolder}>
              {t("vault.newFolder.title")}
            </TernaryButton>
          </HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <div className="mt-4 px-4 text-xl font-semibold md:hidden md:px-0">{t("vault.folders")}</div>
      <UploadsCallout className="mt-4" />
      <FolderList
        selectedFolderId={selectedFolderId}
        onFolderSelection={onFolderSelection}
        className="mt-4 px-4 md:px-0"
      />
    </div>
  );
};

export default Folders;
