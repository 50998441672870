import classNames from "classnames";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { HiXMark } from "react-icons/hi2";

type Props = {
  title?: string;
  open: boolean;
  setOpen: (v: boolean) => void;
  children: ReactNode;
  className?: string;
  "data-testid"?: string;
};

const PopupBottom = ({ title, open, setOpen, children, className, "data-testid": dataTestId }: Props) => {
  const [open2, setOpen2] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [currentTouch, setCurrentTouch] = useState(0);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      setTimeout(() => setOpen2(true), 10);
    }
    return () => {
      document.body.style.overflow = "unset";
      setOpen2(false);
    };
  }, [open]);

  const getNumber = (x: number) => {
    if (x > 0) return x;
    x = -x;
    const scaledX = x / 100;
    const tanhX = Math.tanh(scaledX);
    const result = tanhX;

    return -result * 60;
  };

  const style = useMemo(() => {
    var translateY = getNumber(currentTouch - touchStart);
    return {
      transform: `translateY(${translateY}px)`,
    };
  }, [currentTouch, touchStart]);

  const close = () => {
    setOpen2(false);
    setTimeout(() => setOpen(false), 100);
  };

  return (
    <div className={classNames("", { hidden: !open })}>
      <div
        className={classNames(
          "fixed left-0 top-0 z-[9997] h-screen w-full cursor-pointer bg-gray-900/25 backdrop-blur-lg duration-300",
          { "opacity-0": !open2, "opacity-100": open2 },
        )}
        onClick={close}
      />
      <div
        className={classNames(
          "fixed -bottom-16 left-0 z-[9998] w-full rounded-t-[2rem] bg-white p-4 pb-20 sm:bottom-1/2 sm:left-1/2 sm:max-w-xl sm:-translate-x-1/2 sm:translate-y-1/2 sm:rounded-2xl sm:px-7 sm:pb-8 sm:pt-5 sm:shadow-[0_0_64px_0_rgba(0,0,0,0.2)]",
          className,
          {
            "duration-200": touchStart === 0,
            "translate-y-full": !open2,
            "translate-y-0": open2,
          },
        )}
        style={touchStart !== 0 ? style : {}}
        data-testid={dataTestId}
      >
        <div className="absolute left-1/2 top-5 h-1 w-32 -translate-x-1/2 rounded-full bg-gray-200 sm:hidden" />
        <div
          className="absolute left-0 top-0 z-[9999] h-[3.5rem] w-[calc(100%-3.5rem)] sm:hidden"
          onTouchStart={(e) => {
            // @ts-ignore
            setTouchStart(e.nativeEvent.pageY);
            // @ts-ignore
            setCurrentTouch(e.nativeEvent.pageY);
          }}
          onTouchEnd={(e) => {
            if (currentTouch - touchStart > 200) close();
            setTouchStart(0);
            setCurrentTouch(0);
          }}
          onTouchMove={(e) => {
            // @ts-ignore
            setCurrentTouch(e.nativeEvent.pageY);
          }}
        />
        <div className="flex h-10 justify-end sm:items-center sm:justify-between">
          <div className="hidden w-6 sm:block" />
          {title && <div className="hidden font-semibold sm:block">{title}</div>}
          <button onClick={() => setOpen(false)} className="hidden cursor-pointer p-2 outline-none sm:block sm:p-0">
            <HiXMark className="h-6 w-6 text-gray-900" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PopupBottom;
