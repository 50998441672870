import {useMemo} from "react";
import {PrivateUserDto} from "@neolime-gmbh/api-gateway-client";

export const useStoredCreditCard = (currentUser: PrivateUserDto) => {
  return useMemo(() => {
    if (!currentUser?.storedPaymentInformation || currentUser.storedPaymentInformation.length === 0) return undefined;
    const storedPaymentInformation = currentUser.storedPaymentInformation;

    if (storedPaymentInformation[0].paymentMethod == 'CREDIT_CARD') return storedPaymentInformation[0];

    return undefined;
  }, [currentUser]);
}