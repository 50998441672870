import classNames from "classnames";
import HeaderBar from "components/layouts/HeaderBar";
import ChatUserCard from "../../components/molecules/ChatUserCard";
import Container from "../layouts/Container";
import Layout from "../layouts/Layout";
import ThreeColumnLayout from "components/layouts/ThreeColumnLayout";
import { useCallback } from "react";
import LoadingChatItem from "./LoadingChatItem";
import { useTranslation } from "react-i18next";
import useUserStore from "state/userState";
import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import { HiOutlinePencilSquare, HiOutlineChartBarSquare, HiOutlineCog6Tooth } from "react-icons/hi2";
import LoadingChat from "./LoadingChat";
import LoadingFanInfoProperty from "./LoadingFanInfoProperty";

const LoadingChatLayout = () => {
  const { t } = useTranslation();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const renderLoadingItemComponent = useCallback((key: number) => <LoadingChatItem key={key} />, []);

  const leftColumn = (
    <>
      <div className="sticky top-0 z-50 mx-auto w-full bg-white sm:max-w-xl">
        <div className="md:px-2">
          <HeaderBar alwaysShowDivider={user?.isCreator} className="md:px-2">
            <HeaderBar.SubPage className="sm:pb-2 sm:pt-4">
              <HeaderBar.MainPageHeading className="-mb-1.5 !py-0 text-xl font-semibold !text-gray-700">
                {t("navigation.chat")}
              </HeaderBar.MainPageHeading>
              {user.isCreator && (
                <HeaderBar.MainPageActions className="items-center">
                  <HeaderBar.Link to={user.isVerified ? "/chat/new" : "/verification/start"} className="md:!p-0.5">
                    <HiOutlinePencilSquare className="h-6 w-6 text-gray-800" />
                  </HeaderBar.Link>
                  <HeaderBar.Link
                    to={user.isVerified ? "/chat/broadcasts" : "/verification/start"}
                    className="md:!p-0.5"
                  >
                    <HiOutlineChartBarSquare className="h-6 w-6 text-gray-800" />
                  </HeaderBar.Link>
                  <HeaderBar.Link to={user.isVerified ? "/chat/settings" : "/verification/start"} className="md:!p-0.5">
                    <HiOutlineCog6Tooth className="h-6 w-6 text-gray-800" />
                  </HeaderBar.Link>
                </HeaderBar.MainPageActions>
              )}
            </HeaderBar.SubPage>
          </HeaderBar>
        </div>
      </div>

      <Container noDesktopPadding hasPadding={false} hideScrollbar className="relative bg-white md:px-2" as="main">
        {Array(5)
          .fill(null)
          .map((_, key) => renderLoadingItemComponent(key))}
      </Container>
    </>
  );

  const rightColumn = user.isCreator && (
    <div className="scrollbar-hide flex h-full flex-col gap-4 overflow-y-auto py-4 text-gray-900 chatBreakpoint:py-0 chatBreakpoint:pb-4">
      <HeaderBar className="hidden pb-3 md:max-w-chat chatBreakpoint:block chatBreakpoint:pt-4" alwaysShowDivider>
        <HeaderBar.SubPage className="sm:!pt-4">
          <HeaderBar.Center className="flex items-center">
            <div className="-mt-1 h-3.5 w-3/4 animate-pulse rounded-md bg-gray-100" />
          </HeaderBar.Center>
        </HeaderBar.SubPage>
      </HeaderBar>

      <div className="h-5 w-3/4 animate-pulse rounded-md bg-gray-100" />
      <div className="flex flex-col gap-1 rounded-lg border border-gray-100 bg-white p-3">
        <div className="h-3.5 w-2/3 animate-pulse rounded-md bg-gray-100" />
        <div className="h-5 w-1/2 animate-pulse rounded-md bg-gray-100" />
      </div>

      <LoadingFanInfoProperty />
      <LoadingFanInfoProperty />
    </div>
  );

  return <ThreeColumnLayout leftColumn={leftColumn} centerColumn={<LoadingChat />} rightColumn={rightColumn} />;
};

export default LoadingChatLayout;
