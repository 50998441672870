const LoadingFanInfoProperty = () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        <div className="h-4 w-2/3 animate-pulse rounded-md bg-gray-100" />
        <div className="h-4 w-1/2 animate-pulse rounded-md bg-gray-100" />
      </div>
    </div>
  );
};

export default LoadingFanInfoProperty;
