import useChat from "hooks/useChat.hook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import LoadingChatLayout from "components/chats/LoadingChatLayout";

const ChatWith = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useStatefulNavigate();

  const { openChat } = useChat();

  // open chat with user
  useEffect(() => {
    if (id) {
      openChat(id, true);
    } else navigate("/chat");
  }, []);

  return <LoadingChatLayout />;
};

export default ChatWith;
