import { DisplayableMediaDto } from "@neolime-gmbh/api-gateway-client";
import MediaPopup from "components/media/MediaPopup";
import VideoPlayer from "components/VideoPlayer";

type Props = {
  video: DisplayableMediaDto;
  thumbnail?: DisplayableMediaDto;
  isOpen: boolean;
  onClose: () => void;
};

const VideoFullScreenPopup = ({ video, thumbnail, isOpen, onClose }: Props) => {
  return (
    <MediaPopup isOpen={isOpen} onClose={onClose}>
      <VideoPlayer url={video.url ?? ""} width={video.width} height={video.height} thumbnailUrl={thumbnail?.url} />
    </MediaPopup>
  );
};

export default VideoFullScreenPopup;
