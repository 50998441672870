import { OndatoLanguages, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import StickyBottomBar from "components/StickyBottomBar";
import StatefulLink from "components/atoms/utils/StatefulLink";
import Checkbox from "components/basics/Checkbox";
import Container from "components/layouts/Container";
import Header from "components/layouts/Header";
import Layout from "components/layouts/Layout";
import ValidationError from "components/utilities/ValidationError";
import { termsForCreatorsLink } from "const";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useCallback, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineChevronRight, HiOutlineIdentification } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import Button from "../../components/basics/Button";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import useUserStore from "../../state/userState";

const VerificationStart = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const { t, i18n } = useTranslation();
  const navigate = useStatefulNavigate();

  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const location = useLocation();

  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleOnClick = useCallback(async () => {
    if (hasAgreedToTerms) {
      try {
        window.location.href = await maloumClient.verifications.startIdentityVerification({
          language: i18n.language.startsWith("de") ? OndatoLanguages.DE_DE : OndatoLanguages.EN_GB,
        });
      } catch (error) {
        // TODO: handle error
      }
    } else {
      setHasError(true);
    }
  }, [maloumClient, hasAgreedToTerms]);

  const handleOnBack = useCallback(() => {
    const useFallback = location.state?.host !== window.location.hostname;
    if (useFallback) navigate("/");
    else navigate(-1);
  }, [location]);

  useEffect(() => {
    if (!user || user.isVerified || !user.isCreator) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setHasError(false);
  }, [hasAgreedToTerms]);

  return (
    <>
      <Layout hasNavigation={false} hideNavigationMobile hideVerificationButton hasStickyBottom>
        <Header
          hasBack={false}
          hasNavigation={false}
          onBack={() => navigate("/")}
          title={t("verification.verifyAccount")}
        />
        <Container hasStickyBottom>
          <h1 className={"mt-3 text-center font-serif font-medium text-red-900"} data-testid="verificationStartPage">
            {t("verification.verifyAccount")}
          </h1>

          <div className=" mt-6 flex justify-center rounded-3xl bg-beige-400 p-3">
            <HiOutlineIdentification className="h-[6rem] w-[6rem] stroke-[0.5px]" />
          </div>

          <h3 className="mt-6 text-base font-semibold">{t("verification.fewStepsAhead")}</h3>
          <ol className="colored-counter ml-3 mt-2 grid list-decimal gap-y-1">
            <li>{t("verification.scanPassport")}</li>
            <li>{t("verification.takeASelfie")}</li>
            <li>{t("verification.dataGettingVerified")}</li>
            <li>{t("verification.startEarning")}</li>
          </ol>

          <h3 className="mt-6 text-base font-semibold">{t("verification.dataSecurity")}</h3>
          <p>{t("verification.processDescription")}</p>

          <StatefulLink to="/verification/info" className="mt-2 inline-flex w-fit items-center text-red-900">
            <span className="pr-1">{t("verification.moreInfo")}</span>
            <HiOutlineChevronRight className="h-5 w-5" />
          </StatefulLink>
        </Container>
        <StickyBottomBar>
          {hasError && (
            <ValidationError
              className="pt-0"
              message={t("verification.termsAndConditionsError")}
              hasBackground={false}
            />
          )}

          <Checkbox
            title={
              <span className="text-sm text-gray-600">
                <Trans i18nKey="verification.iAgreeTo">
                  I agree to the
                  <a className="underline" href={termsForCreatorsLink} target="_blank" rel="noreferrer">
                    {t("verification.termsAndConditionsForCreator")}
                  </a>
                  .
                </Trans>
              </span>
            }
            onChange={setHasAgreedToTerms}
            checked={hasAgreedToTerms}
          />
          <div className="mt-4 flex gap-x-2">
            {/* TODO: implement Link component instead of buttons with navigate on click */}
            <Button variant="secondary" text={t("verification.later")} onClick={handleOnBack} />
            <Button text={t("verification.startVerification")} onClick={handleOnClick} />
          </div>
        </StickyBottomBar>
      </Layout>
    </>
  );
};

export default VerificationStart;
