import { useContext, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useLikes = (_id: string) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchLikes = async ({ pageParam = undefined }) => {
    return await maloumClient.posts.getLikes(_id, pageParam, 30);
  };

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["likes", _id],
    fetchLikes,
    {
      getNextPageParam: (lastPage) => lastPage.next || undefined,
    },
  );

  const dataFlatMap = useMemo(() => {
    if (!data) return [];
    return data.pages.flatMap((l) => l.data);
  }, [data]);

  return { likes: dataFlatMap, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage };
};

export default useLikes;
