import { MouseEvent, useCallback, useEffect, useState } from "react";

const useLongPress = ({
  callback,
  preventDefault = false,
  ms = 500,
}: {
  callback: () => void;
  preventDefault?: boolean;
  ms?: number;
}) => {
  const [startLongPress, setStartLongPress] = useState(false);
  const [wasLongPress, setWasLongPress] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (startLongPress) {
      timerId = setTimeout(() => {
        setWasLongPress(true);
        callback();
      }, ms);
    }
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  const handleClickCapture = useCallback(
    (e: MouseEvent) => {
      if (wasLongPress || preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [wasLongPress, preventDefault],
  );

  const handleMouseUp = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setStartLongPress(false);
  };

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: handleMouseUp,
    onClickCapture: handleClickCapture,
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  };
};

export default useLongPress;
