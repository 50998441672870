import { useTranslation } from "react-i18next";
import FanInfoProperty from "./FanInfoProperty";
import useChatItem from "hooks/useChatItem.hook";
import { useParams } from "react-router-dom";
import useCurrency from "hooks/useCurrency.hook";
import { InboxType } from "@neolime-gmbh/api-gateway-client";
import useInbox from "hooks/useInbox.hook";
import { useMessageBarContext } from "contexts/MessageBarContext";
import HeaderBar from "components/layouts/HeaderBar";

const FanInfo = () => {
  const { t } = useTranslation();
  const { focusMessageBar } = useMessageBarContext();

  const { id } = useParams();

  if (!id) return null;

  const { chat, updateNicknameForChatPartner, updateNotesOnChatPartner } = useChatItem(id);
  const { updateInboxNicknameForChatPartner } = useInbox(InboxType.PRIMARY);
  const { displayCurrencyCurrencyWithThousands } = useCurrency();

  const handleUpdateNickname = async (nickname: string) => {
    await updateNicknameForChatPartner(nickname);
    await updateInboxNicknameForChatPartner({ chatId: id, nickname });
    focusMessageBar();
  };

  const handleUpdateNotes = async (notes: string) => {
    await updateNotesOnChatPartner(notes);
    focusMessageBar();
  };

  if (!id || !chat?.chatPartner) return null;

  const chatPartner = chat.chatPartner;

  return (
    <div className="scrollbar-hide flex h-full flex-col gap-4 overflow-y-auto py-4 chatBreakpoint:py-0 chatBreakpoint:pb-4 text-gray-900">
      <HeaderBar className="hidden pb-3 md:max-w-chat chatBreakpoint:block chatBreakpoint:pt-4 chatBreakpoint:pb-[0.45rem]" alwaysShowDivider>
        <HeaderBar.SubPage className="sm:!pt-2 sm:!pb-0">
          <HeaderBar.Center className="flex items-center">
            <span className="text-lg font-semibold text-gray-900">{t("fans.additionalUserInfo")}</span>
          </HeaderBar.Center>
        </HeaderBar.SubPage>
      </HeaderBar>
      <div className={"rounded-md bg-beige-300 p-2 text-sm text-gray-800"}>
        <span>{t("fans.onlyYouCanSee")}</span>
      </div>
      <div className="flex flex-col rounded-lg border border-gray-100 bg-white p-2">
        <p className="text-base font-semibold">{t("fans.totalSpending")}</p>
        <p className="text-xl font-semibold">
          {displayCurrencyCurrencyWithThousands(chatPartner.totalSpendForCreator)}
        </p>
      </div>
      <FanInfoProperty
        currentValue={chatPartner.nickname}
        onUpdate={handleUpdateNickname}
        propertyKey="nickname"
        chatPartner={chatPartner}
      />
      <FanInfoProperty
        currentValue={chatPartner.notes}
        onUpdate={handleUpdateNotes}
        propertyKey="notes"
        chatPartner={chatPartner}
        maxLength={10000}
        useTextarea
      />
    </div>
  );
};

export default FanInfo;
