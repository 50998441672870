import {
  MediaUploadStatus,
  UploadType,
  DisplayableMediaDto,
  ChatProductMessageContentDto,
} from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import ContentTypeIcon from "components/atoms/icons/ContentTypeIcon";
import Button from "components/basics/Button";
import MediaPlaceholder from "components/MediaPlaceholder";
import OrderType from "enums/OrderType";
import { formatDurationInSecondsIntoHoursMinutesSeconds } from "helper/dateAndTimeHelper";
import useCurrency from "hooks/useCurrency.hook";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams } from "react-router-dom";
import useUserStore from "state/userState";
import ChatGridImage from "./ChatGridImage";
import MediaMessage from "./MediaMessage";
import { HiOutlineVideoCamera } from "react-icons/hi2";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import UnprocessedMediaMessage from "./UnprocessedMediaMessage";
import { CheckoutPosthogEvents, useCheckoutPosthog } from "hooks/useCheckoutPosthog";

type ChatProductMessageProps = {
  content: ChatProductMessageContentDto;
  senderId: string;
  handleCheckout: () => void;
};

const ChatProductMessageSingle = ({ content, senderId, handleCheckout }: ChatProductMessageProps) => {
  const { t } = useTranslation();

  const { displayCurrency } = useCurrency();

  const user = useUserStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const firstMediaItem = content?.media?.at(0);

  const height = firstMediaItem?.height ?? 0;
  const width = firstMediaItem?.width ?? 0;
  const style = {
    height: `min(calc(${height / width} * 70vw), calc(${height / width} * 358px))`,
  };

  return firstMediaItem?.uploadStatus === MediaUploadStatus.PROCESSING ? (
    <UnprocessedMediaMessage isSender={user._id === senderId} />
  ) : (
    <div
      className={classNames("relative flex min-h-[15rem] items-center justify-center", {
        "h-80 bg-red-100": firstMediaItem?.type !== "picture" || isError,
      })}
    >
      {firstMediaItem?.type === "picture" && !isError && (
        <>
          <MediaPlaceholder isLoaded={!isLoading} style={style} className="rounded-r-[1.5rem] rounded-tl-[1.5rem]" />
          <img
            src={firstMediaItem.url}
            className={classNames("w-full", {
              hidden: isLoading,
              block: !isLoading,
            })}
            style={{ ...style, borderRadius: "inherit" }}
            alt=""
            onLoad={() => setIsLoading(false)}
            onError={() => setIsError(true)}
          />
          <div className="absolute left-0 top-0 z-[10] h-full w-full rounded-r-[1.5rem] rounded-tl-[1.5rem] bg-black/10" />
        </>
      )}
      <div className="absolute top-0 z-10 flex h-full w-full flex-col items-center justify-center px-6 py-10">
        <div className="flex items-center gap-2">
          <ContentTypeIcon
            type={firstMediaItem?.type === "picture" && !isError ? "lock" : firstMediaItem?.type}
            className={classNames(
              { "h-12 w-12 text-gray-700": firstMediaItem?.type !== "picture" || isError },
              { "h-24 w-24 text-white": firstMediaItem?.type === "picture" && !isError },
            )}
          />
          {firstMediaItem?.type === "video" && (
            <span className="text-2xl">{formatDurationInSecondsIntoHoursMinutesSeconds(firstMediaItem?.length)}</span>
          )}
        </div>
        <h2
          className={classNames(
            "mt-1 text-center text-base",
            { "text-gray-700": firstMediaItem?.type !== "picture" || isError },
            { "font-semibold text-white": firstMediaItem?.type === "picture" && !isError },
          )}
        >
          {firstMediaItem?.type === "picture" ? t("chatProduct.buyToViewImage") : t("chatProduct.buyToViewVideo")}
        </h2>
        <Button
          onClick={handleCheckout}
          text={`${t("chatProduct.unlockFor")} ${content.price && displayCurrency(content.price.net)}`}
          className="mt-6 shrink-0"
        />
      </div>
    </div>
  );
};

type GridMediaItemProps = {
  media: DisplayableMediaDto;
  thumbnail: DisplayableMediaDto;
};

const GridMediaItem = ({ media, thumbnail }: GridMediaItemProps) => {
  return media.type === UploadType.PICTURE || media.uploadStatus === MediaUploadStatus.PROCESSING ? (
    <ChatGridImage url={thumbnail.url} uploadStatus={thumbnail.uploadStatus} type={UploadType.PICTURE} />
  ) : (
    <div className="flex aspect-[130/172] w-full flex-col items-center justify-center gap-1 bg-red-100">
      <HiOutlineVideoCamera className="h-8 w-8" />
      <div className="text-lg font-normal">{formatDurationInSecondsIntoHoursMinutesSeconds(media.length)}</div>
    </div>
  );
};

type Props = {
  chatProduct: ChatProductMessageContentDto;
  isChatProductBought: boolean;
  messageId: string;
  senderId: string;
};

const ChatProductMessage = ({ chatProduct, isChatProductBought, messageId, senderId }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const { displayCurrency } = useCurrency();
  const { capture } = useCheckoutPosthog();
  const user = useUserStore((state) => state.user);
  
  const goToCheckout = async () => {
    capture(CheckoutPosthogEvents.ChatProductCheckout);
    navigate({
      pathname: "/checkout",
      search: createSearchParams({
        type: OrderType.ChatProduct,
        id: messageId,
      }).toString(),
    });
  };

  const { thumbnailsToShow, remainingMedia } = useMemo(() => {
    let thumbnailsToShow: DisplayableMediaDto[] = [];
    if (chatProduct.thumbnails)
      if (chatProduct.thumbnails.length < 4) thumbnailsToShow = chatProduct.thumbnails.slice(0, 2);
      else thumbnailsToShow = chatProduct.thumbnails.slice(0, 4);
    return { thumbnailsToShow, remainingMedia: chatProduct.thumbnails.length - thumbnailsToShow.length };
  }, [chatProduct.thumbnails]);

  return isChatProductBought || senderId === user._id ? (
    <MediaMessage media={chatProduct.media} thumbnails={chatProduct.thumbnails} senderId={senderId} />
  ) : chatProduct.media.length === 1 ? (
    <ChatProductMessageSingle content={chatProduct} senderId={senderId} handleCheckout={goToCheckout} />
  ) : (
    <div className="relative">
      <div className="grid grid-cols-2 gap-1">
        {thumbnailsToShow.map((t, i) => (
          <button className="relative h-fit w-full overflow-hidden rounded-md" onClick={goToCheckout} key={t.url}>
            <GridMediaItem media={chatProduct.media[i]} thumbnail={t} />
            {remainingMedia !== 0 && i === thumbnailsToShow.length - 1 && (
              <div
                className={classNames(
                  "left-0 top-0 hidden h-full w-full items-center justify-center rounded-md bg-gray-950/25 text-4xl font-semibold text-white last:absolute last:flex",
                  {
                    "rounded-tr-[1.5rem]": chatProduct.thumbnails.length < 4,
                  },
                )}
              >{`+${remainingMedia}`}</div>
            )}
          </button>
        ))}
      </div>
      <Button
        onClick={goToCheckout}
        text={`${t("chatProduct.unlockFor")} ${chatProduct.price && displayCurrency(chatProduct.price.net)}`}
        className="mt-1 shrink-0"
      />
    </div>
  );
};

export default ChatProductMessage;
