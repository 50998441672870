import { DeleteMessageUpdateDto, MessageDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import SocketContext from "contexts/SocketContext";
import { useContext, useEffect, useRef, useState } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import useChatsStore from "state/chatsState";
import useMessagesStore from "state/messagesState";

const DEFAULT_STALE_TIME = 1000 * 60 * 5; // 5 minutes

const useMessages = (id: string) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const socket = useContext(SocketContext);
  // TODO: move into state to prevent jumps (deleted messages)
  const messages = useMessagesStore((state) => state.messages[id] || []);
  const { removeMessageFromChat, setMessagesForChat } = useMessagesStore();
  const messagesRef = useRef<MessageDto[]>([]);
  messagesRef.current = messages;
  const [isFetching, setIsFetching] = useState(false);

  const markChatAsRead = useChatsStore((state) => state.markChatAsRead);
  const updateMessageForChatIfExists = useChatsStore((state) => state.updateMessageForChatIfExists);

  const fetchMessages = async (next: QueryFunctionContext<string[], string>) => {
    // we use a seperate isFetching as we have to load chat products extra
    setIsFetching(true);
    return maloumClient.chats.getMessages(id, next?.pageParam);
  };

  const {
    data: messagesData,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["fetch-messages", id], fetchMessages, {
    getNextPageParam: (lastPage) => lastPage.next,
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_STALE_TIME,
  });

  // mark chat as read
  const emitReadChatEvent = () => {
    markChatAsRead(id);
    socket?.emit("read_messages", {
      chat: id,
    });
  };

  const addMessage = async (message: MessageDto) => {
    addMessages([message]);
  };

  const addMessages = async (messages: MessageDto[]) => {
    // mark chat as read
    emitReadChatEvent();
    // update chat
    if (id === messages[0].chat) {
      updateMessageForChatIfExists(id, messages[0], true);
    }

    // add messages to array
    // we need to use messagesRef as messages itself can be out of date when this runs
    // process is possibly in a different thread (sockets)
    let messagesTmp = [
      ...messagesRef.current.filter((m1) => !messages.some((m2) => m2._id === m1._id)),
      ...messages.filter((m) => m.chat === id),
    ];
    // remove duplicates
    const map = new Map();
    messagesTmp.map((m) => map.set(m._id, m));

    messagesTmp = Array.from(map.values()).sort((m1, m2) => new Date(m2.sentAt).getTime() - new Date(m1.sentAt).getTime());
    setMessagesForChat(id, messagesTmp);
  };

  const deleteMessage = async (message: DeleteMessageUpdateDto) => {
    // remove message
    const messagesForChat = useMessagesStore.getState().messages[message.chat];
    if (messagesForChat === undefined) return;
    let messagesTmp = [...messagesForChat];

    if (message.deleteTextOnly) {
      const msgIndex = messagesTmp.findIndex((m) => m._id === message.message);
      
      if ("text" in messagesTmp[msgIndex].content) {
        messagesTmp[msgIndex].content.text = undefined;
      }

      updateMessageForChatIfExists(message.chat, messagesTmp[0], true);

      setMessagesForChat(id, messagesTmp);
    } else {
      messagesTmp = [...messagesTmp.filter((m) => m._id !== message.message)];
      updateMessageForChatIfExists(message.chat, messagesTmp[0], true);
      removeMessageFromChat(message.message, message.chat);
    }
  };

  // setup websocket and disconnect on close
  useEffect(() => {
    socket?.on("receive_message", addMessage);
    socket?.on("upload_completed", addMessage);
    socket?.on("delete_message_update", deleteMessage);
    emitReadChatEvent();
    return () => {
      socket?.off("receive_message", addMessage);
      socket?.off("upload_completed", addMessage);
      socket?.off("delete_message_update", deleteMessage);
    };
  }, [socket]);

  // move messages into state, flatMap and load chat products
  useEffect(() => {
    const loadAndSetMessages = async () => {
      if (!messagesData || messagesData.pages.length === 0 || messagesData.pages[0].data.length === 0)
        setMessagesForChat(id, []);
      else await addMessages(messagesData.pages.flatMap((p) => p.data));
      // finished fetching
      setIsFetching(false);
    };
    loadAndSetMessages();
  }, [messagesData]);

  return {
    messages,
    isLoading,
    isFetching,
    fetchMore: fetchNextPage,
    hasMore: hasNextPage,
  };
};
export default useMessages;
