import { PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import useCurrency from "hooks/useCurrency.hook";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import SubscribePopup from "../../profile/SubscribePopup";
import Button from "../Button";

type Props = {
  user: PublicUserDto;
  className?: string;
};

const SubscribeButton = ({ user, className }: Props) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();
  const [open, setOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnClick = () => {
    // Google Tag Manager: Subscription Popup Opened
    // @ts-expect-error window.dataLayer is not typed
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error window.dataLayer is not typed
    window.dataLayer.push({
      event: "subscription_popup_opened",
      creator_id: user._id,
      net_price_per_month: user.subscriptionPrice.net,
    });
    setOpen(true);
  };

  useEffect(() => {
    if (searchParams.get("openSubscribePopup")) {
      handleOnClick();
      setSearchParams({});
    }
  }, []);

  return user.subscriptionPrice !== undefined && user.subscriptionPrice !== null ? (
    <>
      <Button
        onClick={handleOnClick}
        text={t("subscribeFor", { price: displayCurrency(user.subscriptionPrice.net) })}
        className={className}
      />
      <SubscribePopup creatorId={user._id} open={open} setOpen={setOpen} />
    </>
  ) : (
    /* TODO Refactor to remove this. Take care of other usages of SubscribeButton */
    <></>
  );
};

export default SubscribeButton;
