import i18n from "i18n";
import { createContext, Suspense, useEffect } from "react";

import Loading from "../pages/basics/Loading";
import { usePostHog } from "posthog-js/react";
import useUserStore from "state/userState";

const StartupContext = createContext(null);
export default StartupContext;

/**
 * This context should be used to setup application wide stuff like analytics, general scripts, etc.
 */
export const StartupProvider = ({ children }: { children: React.ReactNode }) => {
  const userState = useUserStore((state) => state.user);
  const posthog = usePostHog();

  useEffect(() => {
    // Prevent right click on images
    const handleContextmenu = (e: MouseEvent) => {
      const element = e.target as HTMLElement;
      if (element.tagName === "IMG") {
        e.preventDefault();
      }
    };

    document.documentElement.lang = i18n.language ? i18n.language : "en";
    document.addEventListener("contextmenu", handleContextmenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    if (userState) {
      if (posthog.get_distinct_id() !== userState._id) {
        posthog?.identify(userState._id, {
          isCreator: userState.isCreator,
          isVerified: userState.isVerified,
        });
      }

      const lastActiveDates = JSON.parse(localStorage.getItem("lastActiveDate") || "{}");
      const lastActiveDateForUser = lastActiveDates?.[userState._id]
        ? new Date(lastActiveDates[userState._id])
        : undefined;

      if (!lastActiveDateForUser || !isDateToday(lastActiveDateForUser)) {
        posthog?.capture("active");
        lastActiveDates[userState._id] = new Date().toISOString();
        localStorage.setItem("lastActiveDate", JSON.stringify(lastActiveDates));
      }
    }
  }, [posthog, userState]);

  return (
    <StartupContext.Provider value={null}>
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </StartupContext.Provider>
  );
};

const isDateToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};
