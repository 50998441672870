import {
  AutomatedMessageDto,
  AutomatedMessageType,
  PrivateUserDto,
  SaveAutomatedMessageRequestDto,
} from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Toggle from "components/atoms/Toggle";
import Button from "components/basics/Button";
import MediaSelectionMultiple from "components/basics/MediaSelectionMultiple";
import Textarea from "components/basics/Textarea";
import HeaderBar from "components/layouts/HeaderBar";
import EditAutomatedMessageLoading from "components/molecules/automated-messages/EditAutomatedMessagesLoading";
import { Form, Formik } from "formik";
import useAutomatedMessage from "hooks/automated-messages/useAutomatedMessage.hook";
import useCurrency from "hooks/useCurrency.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiOutlineUser, HiOutlineXCircle } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import useUserStore from "state/userState";
import { TAutomatedMessageFormValues } from "types/automatedMessages.types";
import { TSelectedMedia } from "types/vault/selectedMedia.type";
import * as Yup from "yup";
import FormikPriceInput from "../../components/basics/from/FormikPriceInput";

type Props = {
  type: AutomatedMessageType;
};

const EditAutomatedMessage = ({ type }: Props) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useStatefulNavigate();
  const { displayCurrency } = useCurrency();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { pathname } = useLocation();

  const { automatedMessage, isLoading, media, saveAutomatedMessage } = useAutomatedMessage(type);

  const [isSaving, setIsSaving] = useState(false);
  const [showErrorOnSave, setShowErrorOnSave] = useState(false);
  const [showNoMediaSelectedError, setShowNoMediaSelectedError] = useState(false);

  const [selectedMedia, setSelectedMedia] = useState<TSelectedMedia[] | undefined>(state?.selectedMedia);
  const [formValues, setFormValues] = useState<TAutomatedMessageFormValues>();

  const handleClickMediaSelectionStart = async () => {
    setShowNoMediaSelectedError(false);
    if (!user.isCreator || (user.isCreator && !user.isVerified)) navigate("/verification/start");
    else if (user.isCreator) {
      const data = {
        ...formValues,
        type,
      };

      navigate("/vault", {
        state: {
          redirectTo: pathname,
          data,
          allowMultipleSelect: true,
          selectedMedia: selectedMedia,
        },
        replace: true,
      });
    }
  };

  const handleOnSubmit = async (values: TAutomatedMessageFormValues) => {
    setIsSaving(true);
    setShowErrorOnSave(false);

    try {
      const tempAutomatedMessage: SaveAutomatedMessageRequestDto = {
        isActive: values.isActive,
        content: {
          text: values.text === "" ? undefined : values.text,
          price: +values.price > 0 ? +values.price : undefined,
          mediaIds: selectedMedia?.map((m) => m._id) ?? [],
        },
      };

      await saveAutomatedMessage(tempAutomatedMessage);
      navigate("/automated-messages", { replace: true });
    } catch (e) {
      console.log(e);
      setShowErrorOnSave(true);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (
      state?.data?.type !== type &&
      media &&
      !_.isEqual(
        media?.map((m) => m._id),
        selectedMedia?.map((m) => m._id),
      )
    ) {
      setSelectedMedia(media);
    } else if (state?.data?.type !== type) {
      setSelectedMedia(undefined);
    }
  }, [type]);

  useEffect(() => {
    if (!selectedMedia) {
      setSelectedMedia(media);
    }
  }, [media]);

  if (isLoading) return <EditAutomatedMessageLoading />;

  return (
    <div className="full-height flex flex-col">
      <AutomatedMessageHeader type={type} />
      <Formik
        initialValues={state?.data?.type === type ? state.data : convertDtoToFormValues(automatedMessage)}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          isActive: Yup.boolean().required(t("validation.required") as string),
          text: Yup.string()
            .min(1, ({ min }) => t("validation.minLength", { minLength: min }))
            .max(2000, ({ max }) => t("validation.maxLength", { maxLength: max }))
            .optional(),
          price: Yup.number()
            .transform((value) => (value === 0 || value === "0" ? undefined : value))
            .transform((value) => (isNaN(value) ? undefined : value))
            .optional()
            .typeError(t("validation.onlyNumbers") as string)
            .min(1, ({ min }) =>
              t("validation.amountBetween", { min: displayCurrency(min), max: displayCurrency(9999) }),
            )
            .max(9999, ({ max }) =>
              t("validation.amountBetween", { min: displayCurrency(1), max: displayCurrency(max) }),
            )
            .test("is-decimal", t("validation.only2Decimals") as string, (val) => {
              if (val !== undefined) {
                return /^\d+(\.\d{0,2})?$/.test(val.toString());
              }
              return true;
            }),
        })}
        validate={(values: TAutomatedMessageFormValues) => {
          const errors: { [key: string]: string } = {};
          setShowNoMediaSelectedError(false);

          const hasText = values.text && values.text.trim().length > 0;
          const hasMedia = selectedMedia && selectedMedia.length > 0;
          const hasPrice = values.price && values.price > 0;

          if (hasPrice && !selectedMedia?.length) {
            setShowNoMediaSelectedError(true);
            // we need this as the form will submit with an empty error object
            errors.media = t("validation.required") as string;
          }
          if (!hasText && !hasMedia) {
            setShowNoMediaSelectedError(true);
            errors.text = t("validation.required") as string;
          }

          return errors;
        }}
        onSubmit={handleOnSubmit}
      >
        {({
          values,
          setFieldValue,
          isValid,
        }: {
          values: TAutomatedMessageFormValues;
          setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
          isValid: boolean;
        }) => {
          setFormValues(values);

          return (
            <Form className="flex flex-grow flex-col gap-2">
              <div className="mt-6 flex flex-col gap-3 px-4 md:px-0">
                <div className="mb-2 flex items-center gap-2">
                  <HiOutlineUser className="h-6 w-6" />
                  <span className="font-bold">{t(`automatedMessages.${type}.toggle`)} </span>
                  <Toggle
                    className="ml-auto"
                    name="isActive"
                    isChecked={values.isActive}
                    onChange={(isChecked) => {
                      setFieldValue("isActive", isChecked);
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <Textarea
                    label={t(`automatedMessages.${type}.messageDescription`) ?? ""}
                    type="text"
                    name="text"
                    className="flex flex-col gap-1"
                    placeholder={t("automatedMessages.messagePlaceholder") ?? ""}
                    value={values.text}
                    maxLength={2000}
                    autogrow
                  />
                </div>
                <div className="mt-2 flex flex-col gap-1">
                  <span className="text-sm">{t("add-media")}</span>
                  <MediaSelectionMultiple
                    selectedMedia={selectedMedia}
                    showNoMediaSelectedError={showNoMediaSelectedError}
                    onRemoveMedia={(_id) => {
                      setSelectedMedia(selectedMedia?.filter((m) => m._id !== _id));
                    }}
                    handleClickMediaSelectionStart={handleClickMediaSelectionStart}
                  />
                </div>
                <div className="w-full text-sm">
                  <FormikPriceInput
                    label={t("automatedMessages.price-to-unlock") || ""}
                    name="price"
                    value={values.price ? values.price.toString() : ""}
                    submitOnEnter={false}
                    placeholder={"0,00"}
                  />
                </div>
              </div>
              <div className="flex-2 mb-4 mt-auto min-h-10 px-4 md:px-0">
                {showErrorOnSave && (
                  <div className="flex animate-fadeOutWithDelay items-center justify-center gap-2 py-2 text-sm text-red-800">
                    <HiOutlineXCircle className="h-4 w-4" />
                    <span>{t("tryAgain")}</span>
                  </div>
                )}
                <Button
                  text={isSaving ? "" : t("save")}
                  IconFront={
                    isSaving
                      ? ({ className }: { className: string }) => (
                          <AiOutlineLoading3Quarters className={classNames("animate-spin", className)} />
                        )
                      : undefined
                  }
                  type="submit"
                  disabled={isSaving || !isValid}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const AutomatedMessageHeader = ({ type }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  return (
    <HeaderBar alwaysShowDividerXL>
      <HeaderBar.SubPage>
        <HeaderBar.Left className="ml-0 basis-0 md:basis-full">
          <HeaderBar.Cancel onClick={() => navigate("/automated-messages", { replace: true })} />
        </HeaderBar.Left>
        <HeaderBar.Center>
          <HeaderBar.Title>{t(`automatedMessages.${type}.title`)}</HeaderBar.Title>
        </HeaderBar.Center>
        <HeaderBar.Right className="mr-0" />
      </HeaderBar.SubPage>
    </HeaderBar>
  );
};

const convertDtoToFormValues = (automatedMessage: AutomatedMessageDto | undefined): TAutomatedMessageFormValues => ({
  isActive: automatedMessage?.isActive || false,
  text: automatedMessage?.text || "",
  price: automatedMessage?.price || 0,
});

export default EditAutomatedMessage;
