import { PublicUserDto, RelatedUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import ProfileImage from "components/basics/ProfileImage";
import { generateTimeTagForFans } from "helper/dateAndTimeHelper";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BaseComponent } from "types/components/component.type";

type Props = {
  member: RelatedUserDto;
  renderAction?: (userId: string) => ReactNode;
};

const RelatedUserItem = ({ member, renderAction = () => <></> }: Props) => {
  const { t } = useTranslation();

  const user = member.user;

  const description = useMemo(() => {
    if (member.isSubscribed && member.subscribedSince)
      return `${t("fans.subscribedSince")} ${generateTimeTagForFans(new Date(member.subscribedSince))}`;
    if (member.isFollowing && member.followingSince)
      return `${t("fans.followingSince")} ${generateTimeTagForFans(new Date(member.followingSince))}`;
    return undefined;
  }, [member]);

  return (
    <div className="flex min-h-[2.625rem] justify-between gap-3">
      <Link
        className="flex items-center gap-3"
        to={user.isVerified ? `/creator/${user.username}` : `/chat/with/${user._id}`}
      >
        <BaseUserItem user={user} description={description} />
      </Link>

      {renderAction(user._id)}
    </div>
  );
};

const BaseUserItem = ({ user, description }: { user: PublicUserDto; description?: string }) => {
  return (
    <>
      <ProfileImage url={user.profilePictureThumbnail?.url} className="h-10 w-10" />

      <div className="text-left">
        <div>{user.username}</div>
        {description && <div className="text-xs text-gray-500">{description}</div>}
      </div>
    </>
  );
};

type MemberItemLoadingProps = BaseComponent;

const RelatedUserItemLoading = ({ className, "data-testid": dataTestId }: MemberItemLoadingProps) => {
  return <div className={classNames("h-10 w-full rounded-md bg-gray-100", className)} data-testid={dataTestId} />;
};

export default RelatedUserItem;
export { RelatedUserItemLoading };
