import { useEffect } from "react";
import { useChatScrollStore } from "state/chatScrollState";

export const useSavedScroll = ({ scrollContainerRef }: { scrollContainerRef: React.RefObject<HTMLDivElement> }) => {
  const savedScroll = useChatScrollStore((state) => state.messageScrollPosition);

  useEffect(() => {
    if (typeof savedScroll === "number" && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      requestAnimationFrame(() => {
        container.style.transform = "translateY(0)";
        container.scrollTop = savedScroll;
      });
    }
  }, []);
};
