import TernaryButton from "components/atoms/buttons/TernaryButton";
import HeaderBar from "components/layouts/HeaderBar";
import ListList from "components/organisms/lists/ListList";
import { useTranslation } from "react-i18next";

type Props = {
  selectedListId?: string;
  onListSelection: (listId: string) => void;
  onCreateList: () => void;
};

const Lists = ({ selectedListId, onListSelection, onCreateList }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <HeaderBar alwaysShowDividerXL>
        <HeaderBar.SubPage>
          <HeaderBar.Left className="md:ml-0">
            <span className="hidden text-xl font-semibold md:block">{t("lists.title")}</span>
            <HeaderBar.BackButton className="md:hidden" />
          </HeaderBar.Left>
          <HeaderBar.Center className="md:hidden">
            <HeaderBar.Title>{t("lists.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right className="mr-0 md:-mr-4">
            <TernaryButton variant="red" size="sm" onClick={onCreateList}>
              {t("lists.new.cta")}
            </TernaryButton>
          </HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <ListList selectedListId={selectedListId} onListSelection={onListSelection} className="mt-4 px-4 md:px-0" />
    </div>
  );
};

export default Lists;
