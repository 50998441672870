import { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import TextInput from "components/basics/TextInput";
import Button from "components/basics/Button";
import PopUp from "components/popup/PopUp";
import { ChatPartnerDto } from "@neolime-gmbh/api-gateway-client";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { FanInfoPropertyKey } from "types/chat.types";
import Textarea from "components/basics/Textarea";
import { isMobileDevice } from "helper/deviceHelpers";

interface FanInfoPropertyProps {
  currentValue: string | undefined;
  onUpdate: (value: string) => Promise<void>;
  propertyKey: FanInfoPropertyKey;
  maxLength?: number;
  chatPartner: ChatPartnerDto;
  useTextarea?: boolean;
}

const FanInfoProperty = ({
  currentValue,
  onUpdate,
  propertyKey,
  chatPartner,
  maxLength = 50,
  useTextarea = false,
}: FanInfoPropertyProps) => {
  const { t } = useTranslation();
  const [, setValueUpdated] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const hasValue = Boolean(currentValue);

  const handleSubmit = async (values: { value: string }) => {
    try {
      await onUpdate(values.value);
      setValueUpdated(true);
      setPopupOpen(false);
      setTimeout(() => setValueUpdated(false), 3000);
    } catch (error) {
      console.error("Error updating value:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    value: Yup.string().max(maxLength, t("validation.max", { max: maxLength }) ?? ""),
  });

  const handleKeyDown = (e: React.KeyboardEvent, submitForm: () => void) => {
    if (isMobileDevice()) return;

    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Let the default behavior handle new line
        return;
      }
      // Prevent default to avoid new line
      e.preventDefault();
      submitForm();
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        <p className="font-semibold">{t(`fans.${propertyKey}For`, { username: chatPartner?.username })}</p>
        {hasValue && <p className="whitespace-pre-wrap break-words">{currentValue}</p>}
        <button onClick={() => setPopupOpen(true)} className="text-left text-red-900 hover:text-red-800">
          {hasValue ? (
            <div className="flex items-center gap-1">
              <HiOutlinePencilSquare className="h-4 w-4" />
              {t(`edit`)}
            </div>
          ) : (
            t(`fans.${propertyKey}Add`)
          )}
        </button>
      </div>

      <PopUp isOpen={popupOpen} onClose={() => setPopupOpen(false)} title={t(`fans.${propertyKey}Add`) ?? ""}>
        <Formik
          initialValues={{ value: currentValue || "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ submitForm }) => (
            <Form className="mt-4">
              <div className="flex flex-col gap-1 text-start">
                {useTextarea ? (
                  <Textarea
                    label={t(`fans.${propertyKey}Label`)}
                    name="value"
                    placeholder={t(`fans.${propertyKey}Label`) ?? ""}
                    variant="underline"
                    maxLength={maxLength}
                    onKeyDown={(e: React.KeyboardEvent) => handleKeyDown(e, submitForm)}
                    autoFocus
                    //handles different autofocus behaviour difference for textareas
                    onFocus={(e: React.FocusEvent<HTMLTextAreaElement>) => {
                      const textarea = e.target;
                      const value = textarea.value;
                      textarea.value = "";
                      textarea.value = value;
                      textarea.scrollTop = textarea.scrollHeight;
                    }}
                  />
                ) : (
                  <TextInput
                    label={t(`fans.${propertyKey}Label`)}
                    name="value"
                    placeholder={t(`fans.${propertyKey}Label`)}
                    variant="underline"
                    spacing="mb-1"
                    maxLength={maxLength}
                    onKeyDown={(e: React.KeyboardEvent) => handleKeyDown(e, submitForm)}
                    autoFocus
                  />
                )}
              </div>
              <div className="mt-4 flex justify-end">
                <Button variant="primary" text={t("save")} type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      </PopUp>
    </div>
  );
};

export default FanInfoProperty;
