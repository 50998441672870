import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import {
  morePopUpNavigationLinks,
  navigationLinks,
  ordersLink,
  verificationLink,
} from "const/navigation/navigationLinks";
import { useMemo } from "react";
import { HiOutlinePlusCircle, HiPlusCircle } from "react-icons/hi2";
import useNavigationStore from "state/navigationState";
import useUserStore from "state/userState";
import { NavItemListType, UserRole } from "types/navigation.types";

const sortNavList = (list: NavItemListType[], userRole: UserRole): NavItemListType[] => {
  return list.filter((item) => item.userRoles?.includes(userRole)).sort((a, b) => a.order - b.order);
};

const useNavList = () => {
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const userRole = useMemo(() => (user?.isCreator ? "creator" : "fan"), [user]);
  const { openCreationPopUp, isCreationPopUpOpen } = useNavigationStore();

  const navigationButtons: NavItemListType[] = [
    {
      Icon: HiOutlinePlusCircle,
      SelectedIcon: HiPlusCircle,
      title: "create",
      type: "button",
      order: 3,
      userRoles: ["creator"],
      onClick: openCreationPopUp,
      isSelected: isCreationPopUpOpen,
    },
  ];

  const navigationItems = useMemo(() => {
    if (user?.isVerified) {
      return [...navigationLinks, ...navigationButtons];
    } else {
      return [...navigationLinks, verificationLink];
    }
  }, [user?.isVerified]);

  const moreNavigationItems = useMemo(() => {
    if (user?.introduction?.madeProductSale || user?.introduction?.madeProductPurchase) {
      return [...morePopUpNavigationLinks, ordersLink];
    } else {
      return morePopUpNavigationLinks;
    }
  }, [user?.introduction?.madeProductSale, user?.introduction?.madeProductPurchase]);

  const navList = sortNavList(navigationItems, userRole);
  const moreNavList = sortNavList(moreNavigationItems, userRole);

  return { navList, moreNavList };
};
export default useNavList;
