import classNames from "classnames";
import ChangePriceIllustration from "components/atoms/illustration/ChangePriceIllustration";
import Button from "components/basics/Button";
import PopUp from "components/popup/PopUp";
import MaloumClientContext from "contexts/MaloumClientContext";
import { Form, Formik } from "formik";
import useCurrency from "hooks/useCurrency.hook";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineCheckCircle, HiOutlineXCircle } from "react-icons/hi2";
import * as Yup from "yup";
import FormikPriceInput from "../basics/from/FormikPriceInput";
import { ChatProductMessageContentDto } from "@neolime-gmbh/api-gateway-client";

type Props = {
  messageId: string;
  chatProduct: ChatProductMessageContentDto;
  wasBroadcasted: boolean;
  open: boolean;
  onClose: () => void;
};

const EditChatProcutPricePopup = ({ messageId, chatProduct, wasBroadcasted, open, onClose }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const [step, setStep] = useState(0);
  const [error, setError] = useState<boolean>(false);

  const [price, setPrice] = useState(chatProduct?.price.net ?? 0);

  // get title of popup
  const popUpTitle = useMemo(() => {
    switch (step) {
      case 0:
        return t("changeChatProductPrice.changePrice.title");
      case 1:
        return t("changeChatProductPrice.areYouSure.title");
      default:
        return error ? t("changeChatProductPrice.error.title") : t("changeChatProductPrice.success.title");
    }
  }, [step]);

 
  const handleSaveNewPrice = (netAmount: number = price) => {
    if (!chatProduct) return;
    maloumClient.messages.updateMessagePrice(messageId, { netAmount }).catch(() => setError(true));
    setStep(2);
  };

  // reset step on open
  useEffect(() => {
    if (open) {
      setStep(0);
      setPrice(chatProduct?.price.net ?? 0);
    }
  }, [open]);

  return (
    <PopUp isOpen={open} onClose={onClose} title={popUpTitle}>
      {
        step === 0 ? (
          <PopupContentStep0
            price={price}
            nextStep={(newPrice: number) => {
              if (wasBroadcasted) {
                setPrice(newPrice as number);
                setStep(1);
              } else {
                handleSaveNewPrice(newPrice);
              }
            }}
          />
        ) : step === 1 ? (
          <PopupContentStep1 onBack={() => setStep(0)} nextStep={handleSaveNewPrice} />
        ) : (error ? <PopupContentStep2Error nextStep={onClose} /> : <PopupContentStep2Success nextStep={onClose} />)
      }
    </PopUp>
  );
};

export default EditChatProcutPricePopup;

type StepProps = {
  nextStep: () => void;
  className?: string;
  "data-testid"?: string;
};

type SetPriceProps = {
  price: number;
  nextStep: (newPrice: number) => void;
  className?: string;
  "data-testid"?: string;
};

const PopupContentStep0 = ({ price, nextStep, className, "data-testid": dataTestId }: SetPriceProps) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  const decimalSchema = Yup.number()
    .typeError(t("validation.onlyNumbers") ?? "")
    .min(1, t("validation.amountBetween", { min: displayCurrency(1), max: displayCurrency(9999) }) ?? "")
    .max(9999, t("validation.amountBetween", { min: displayCurrency(1), max: displayCurrency(9999) }) ?? "")
    .test("is-decimal", t("validation.only2Decimals") ?? "", (val) => {
      if (val !== undefined) {
        return /^\d+(\.\d{0,2})?$/.test(val.toString());
      }
      return false;
    })
    .required(t("validation.required") ?? "");

  return (
    <Formik
      initialValues={{
        price: price?.toFixed(2) ?? "",
      }}
      validationSchema={Yup.object().shape({
        price: decimalSchema,
      })}
      onSubmit={(values) => {
        nextStep(parseFloat(values.price));
      }}
    >
      {({ isValid }) => (
        <Form className={classNames("mt-4 text-left", className)} data-testid={dataTestId}>
          <div className="mb-4">
            <FormikPriceInput
              data-testid="net-price-input"
              name="price"
              textSizeClassName="text-2xl"
              className="text-center font-semibold"
            />
          </div>
          <Button disabled={!isValid} type="submit" text={t("changeChatProductPrice.changePrice.setPrice")} />
        </Form>
      )}
    </Formik>
  );
};

type PopupContentStep1Props = {
  onBack: () => void;
} & StepProps;

const PopupContentStep1 = ({ nextStep, onBack, className, "data-testid": dataTestId }: PopupContentStep1Props) => {
  const { t } = useTranslation();

  return (
    <div className={classNames("", className)} data-testid={dataTestId}>
      <ChangePriceIllustration className="mx-auto mb-4 h-24" />
      <div className="text-center text-sm text-gray-500">{t("changeChatProductPrice.areYouSure.description")}</div>
      <div className="flex gap-2 pt-6">
        <Button text={t("changeChatProductPrice.areYouSure.editPrice")} onClick={onBack} variant="secondary" />
        <Button text={t("changeChatProductPrice.areYouSure.saveChanges")} onClick={() => nextStep()} />
      </div>
    </div>
  );
};

const PopupContentStep2Success = ({ nextStep, className, "data-testid": dataTestId }: StepProps) => {
  const { t } = useTranslation();
  return (
    <div className={classNames("", className)} data-testid={dataTestId}>
      <HiOutlineCheckCircle className="mx-auto my-2 h-16 w-16 text-red-900" />
      <div>{t("changeChatProductPrice.success.description")}</div>
      <Button text={t("gotIt")} onClick={nextStep} className="mt-6" />
    </div>
  );
};

const PopupContentStep2Error = ({ nextStep, className, "data-testid": dataTestId }: StepProps) => {
  const { t } = useTranslation();
  return (
    <div className={classNames("", className)} data-testid={dataTestId}>
      <HiOutlineXCircle className="mx-auto my-2 h-16 w-16" />
      <div>{t("changeChatProductPrice.error.description")}</div>
      <Button text={t("gotIt")} onClick={nextStep} className="mt-6" />
    </div>
  );
};
