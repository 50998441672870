import { MediaUploadStatus } from "@neolime-gmbh/api-gateway-client";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import StatefulLink from "components/atoms/utils/StatefulLink";
import ProfileImage, { ProfileImageWithLink } from "../basics/ProfileImage";
import classNames from "classnames";

type Props = {
  username?: string;
  isCreator?: boolean;
  url?: string;
  uploadStatus?: MediaUploadStatus;
  userIsVerified?: boolean;
  nickname?: string;
};

const ChatUserCard = ({ url, username, isCreator, uploadStatus, userIsVerified, nickname }: Props) => {
  if (!username) {
    return <div className="h-6 w-full animate-pulse rounded-md bg-gray-100" />;
  }

  const hasNickname = Boolean(nickname);
  const usernameToDisplay = hasNickname ? `@${username}` : username;

  return (
    <div className={classNames("flex w-full items-center justify-center gap-2", hasNickname && "pt-2 md:pt-0")}>
      {isCreator && userIsVerified ? (
        <>
          <ProfileImageWithLink
            url={url}
            className={"h-8 w-8"}
            to={`/creator/${username}`}
            uploadStatus={uploadStatus}
          />
          <StatefulLink to={`/creator/${username}`}>
            <div className="flex flex-col items-start">
              {hasNickname && (
                <span className="max-w-full overflow-hidden break-words break-all text-sm font-medium">{nickname}</span>
              )}
              <span
                className={classNames(
                  "notranslate flex max-w-full items-center gap-1 overflow-hidden break-words break-all",
                  hasNickname ? "text-xs text-gray-500" : "text-sm",
                )}
              >
                {usernameToDisplay} {userIsVerified && <VerifiedUserIcon className={"h-4 w-4 shrink-0"} />}
              </span>
            </div>
          </StatefulLink>
        </>
      ) : (
        <>
          <ProfileImage className={"h-8 w-8"} url={url} uploadStatus={uploadStatus} />
          <div className="flex flex-col items-start">
            {hasNickname && (
              <span className="max-w-full overflow-hidden break-words break-all text-sm font-medium">{nickname}</span>
            )}
            <span
              className={classNames(
                "notranslate max-w-full overflow-hidden break-words break-all",
                hasNickname ? "text-xs text-gray-500" : "text-sm",
              )}
            >
              {usernameToDisplay}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatUserCard;
