import classNames from "classnames";
import { FieldHookConfig, useField } from "formik";
import ValidationError from "../../utilities/ValidationError";
import PriceInput from "./PriceInput";

type Props = {
  label?: string;
  required?: boolean;
  className?: string;
  outerClassName?: string;
  textSizeClassName?: "text-xs" | "text-sm" | "text-base" | "text-lg" | "text-xl" | "text-2xl";
  placeholder?: string;
  prefix?: string;
  additionalInformation?: string;
  submitOnEnter?: boolean;
  autoFocus?: boolean;
};

const FormikPriceInput = ({
  label,
  required,
  className,
  outerClassName,
  textSizeClassName,
  placeholder = "0.00",
  prefix,
  additionalInformation,
  submitOnEnter = true,
  autoFocus,
  ...props
}: Props & FieldHookConfig<number>) => {
  const [field, meta] = useField(props);

  return (
    <div className={classNames("flex flex-col", outerClassName)}>
      {label && (
        <label htmlFor={field.name} className="text-sm">
          {label} {required && <>*</>}
        </label>
      )}
      <PriceInput
        id={props.name}
        prefix={prefix}
        placeholder={placeholder}
        submitOnEnter={submitOnEnter}
        className={className}
        textSizeClassName={textSizeClassName}
        autoFocus={autoFocus}
        {...field}
        {...props}
      />
      {additionalInformation && <p className="mt-1 text-xs text-gray-700">{additionalInformation}</p>}
      {meta.touched && meta.error && <ValidationError className="mt-2" message={meta.error} hasBackground={false} />}
    </div>
  );
};

export default FormikPriceInput;
