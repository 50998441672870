import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import NavItem from "components/atoms/nav/NavItem";
import SidebarPopUp from "components/molecules/SidebarPopUp";
import { supportEmail } from "const";
import useNavList from "hooks/useNavList.hook";
import { Fragment } from "react";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import { ReactComponent as MaloumLogo } from "svg/logo/maloum_logo.svg";
import { ReactComponent as MaloumSymbol } from "svg/logo/maloum_symbol.svg";

const Sidebar = () => {
  const { navList } = useNavList();

  return (
    <section className="fixed top-0 z-10 hidden h-full w-20 sm:block lg:w-60">
      <div className="absolute left-0 top-0 flex h-full w-full flex-col justify-between border-r border-gray-200 px-4 pb-4 pt-8 ">
        <nav className="flex flex-col">
          <MaloumLogo className="ml-2.5 hidden h-5 w-[7.1875rem] fill-red-900 lg:flex" />
          <MaloumSymbol className="ml-3 h-5 w-5 fill-red-900 lg:hidden" />

          <ul className="mt-8 grid grid-cols-1 gap-2">
            {navList.map((item) => (
              <li key={item.title}>
                {/* @ts-expect-error NavItem typing is not correct */}
                <NavItem
                  Icon={item.Icon}
                  SelectedIcon={item.SelectedIcon}
                  type={item.type}
                  title={item.title}
                  to={item.to}
                  onClick={item.onClick}
                  isSelected={item.isSelected}
                  disabled={item.disabled}
                />
              </li>
            ))}
          </ul>
        </nav>

        <footer className="flex flex-col">
          <SidebarPopUp />
          <hr className="my-2 border-gray-200" />

          <a href={`mailto:${supportEmail}`} className="hidden text-sm text-gray-500 lg:block">
            {supportEmail}
          </a>

          <Popover className="relative lg:hidden">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    "flex w-full items-center gap-3 rounded-md border-2 border-transparent p-2 text-gray-700 outline-none duration-100 hover:bg-beige-300 hover:text-gray-500 focus:border-blue-violet active:bg-beige-400 active:text-gray-800 disabled:bg-gray-100 disabled:text-gray-300",
                    {
                      "bg-beige-400": open,
                    },
                  )}
                >
                  <HiOutlineQuestionMarkCircle className={"h-6 w-6"} />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute bottom-0 left-10 z-[600] mt-5 flex w-screen max-w-min px-4">
                    <div className="shrink rounded-md bg-white p-2 text-gray-700 shadow-lg">
                      <a href={`mailto:${supportEmail}`} className="text-sm text-gray-700">
                        {supportEmail}
                      </a>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </footer>
      </div>
    </section>
  );
};

export default Sidebar;
