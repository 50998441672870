import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyChats } from "svg/undraw_empty_chats.svg";

const EmptyChatList = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 flex-grow flex-col items-center justify-center gap-6 px-6 pt-4">
      <EmptyChats className="h-44 w-full" />
      <span className="text-sm text-gray-500">{t("no-chats")}</span>
    </div>
  );
};

export default EmptyChatList;
