import Button from "components/basics/Button";
import PopupBottom from "components/molecules/PopupBottom";
import { useAgeVerification } from "hooks/checkout/useAgeVerification.hook";
import { useTranslation } from "react-i18next";
import { HiCheckCircle, HiOutlineCamera } from "react-icons/hi2";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  className?: string;
  overrideRedirect?: string;
};

const AgeVerificationPopup = ({ open, setOpen, className, overrideRedirect }: Props) => {
  const { t } = useTranslation();
  const { onVerifyNow, loading } = useAgeVerification({ overrideRedirect });

  return (
    <PopupBottom open={open} setOpen={setOpen} className={className}>
      <div className="flex flex-col items-center justify-center gap-3 px-4">
        <div className="flex size-14 items-center justify-center rounded-full bg-red-100 text-red-900">
          <HiOutlineCamera className="size-6" />
        </div>
        <div className="mt-1 font-semibold">{t("popups.ageVerification.description")}</div>
        <div className="flex w-full flex-col gap-1">
          {[
            t("popups.ageVerification.noIdNeeded"),
            t("popups.ageVerification.takesOnlySeconds"),
            t("popups.ageVerification.onlyNeededOnce"),
            t("popups.ageVerification.yourDataIsSafe"),
          ].map((text) => (
            <div className="flex items-center gap-1.5" key={text}>
              <HiCheckCircle className="size-6 text-green-600" />
              <span className="text-sm text-gray-700">{text}</span>
            </div>
          ))}
        </div>
        <div className="mt-4 flex w-full justify-center gap-2.5">
          <Button
            text={t("verification.later")}
            onClick={() => setOpen(false)}
            className="w-full"
            variant="secondary"
          />
          <Button
            disabled={loading}
            text={t("verification.verifyAccountNow")}
            onClick={onVerifyNow}
            className="w-full"
          />
        </div>
      </div>
    </PopupBottom>
  );
};

export default AgeVerificationPopup;
