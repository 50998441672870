import { ChatDto, ExclusiveChatType } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import SecondaryButton from "components/atoms/buttons/SecondaryButton";
import Card from "components/atoms/Card";
import Button from "components/basics/Button";
import SubscribePopup from "components/profile/SubscribePopup";
import OrderType from "enums/OrderType";
import { CheckoutPosthogEvents, useCheckoutPosthog } from "hooks/useCheckoutPosthog";
import useCurrency from "hooks/useCurrency.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams } from "react-router-dom";

type Props = {
  chat: ChatDto;
  className?: string;
};

const UnlockChatCard = ({ chat, className }: Props) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();
  const navigate = useStatefulNavigate();
  const { capture } = useCheckoutPosthog();
  const [isSubscribePopupOpen, setIsSubscribePopupOpen] = useState(false);

  const subscribersOnly = chat.chatPartner.chatAccessSettings?.exclusiveChatType === ExclusiveChatType.SUBSCRIBERS_ONLY;

  const handleUnlockChat = () => {
    capture(CheckoutPosthogEvents.TipCheckout);
    navigate(
      {
        pathname: "/checkout",
        search: createSearchParams({
          type: OrderType.ChatUnlock,
          amount: (chat.chatPartner.chatAccessSettings?.chatFee?.net ?? 0).toString(),
          id: chat.chatPartner.username,
        }).toString(),
      },
      { replace: true },
    );
  };

  const Actions = useMemo(() => {
    if (subscribersOnly)
      return (
        <Button
          onClick={() => setIsSubscribePopupOpen(true)}
          text={t("subscribeFor", { price: displayCurrency(chat.chatPartner.subscriptionPrice.net) })}
        />
      );
    else
      return (
        <>
          <Button onClick={() => setIsSubscribePopupOpen(true)} text={t("subscribe")} />
          <SecondaryButton onClick={handleUnlockChat} className="w-full">
            {t("unlockFor", { price: displayCurrency(chat.chatPartner.chatAccessSettings?.chatFee?.net ?? 0) })}
          </SecondaryButton>
        </>
      );
  }, [chat]);

  return (
    <>
      <div className={classNames("w-full rounded-t-md bg-white", className)}>
        <Card className="px-4 py-4">
          <div>
            {subscribersOnly
              ? t("chatUnlockBySubscribing", { username: chat.chatPartner.username })
              : t("chatUnlock", { username: chat.chatPartner.username })}
          </div>
          <div className="mt-4 flex gap-2">{Actions}</div>
        </Card>
      </div>
      <SubscribePopup creatorId={chat.chatPartner._id} open={isSubscribePopupOpen} setOpen={setIsSubscribePopupOpen} />
    </>
  );
};

export default UnlockChatCard;
