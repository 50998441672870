import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import TwoColumnLayout from "components/layouts/TwoColumnLayout";
import EmptyListSelection from "components/molecules/lists/EmptyListSelection";
import CreateNewListPopup from "components/molecules/lists/popups/CreateNewListPopup";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import useUserStore from "state/userState";
import ListDetail from "./ListDetail";
import Lists from "./Lists";

const ListsParent = () => {
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { state } = useLocation();
  const listId = state?.listId as string | undefined;

  const [createPopupOpen, setCreatePopupOpen] = useState(false);

  const handleListSelection = (listId: string) => {
    navigate(".", { state: { listId } });
  };

  const handleCreateList = () => {
    if (user.isVerified) setCreatePopupOpen(true);
    else navigate("/verification/start");
  };

  const center = (
    <>
      <div className="hidden h-full md:block">
        {listId ? <ListDetail listId={listId} /> : <EmptyListSelection onCreateList={handleCreateList} />}
      </div>
      <div className="md:hidden">
        {listId ? (
          <ListDetail listId={listId} />
        ) : (
          <Lists selectedListId={listId} onListSelection={handleListSelection} onCreateList={handleCreateList} />
        )}
      </div>

      <CreateNewListPopup open={createPopupOpen} onClose={() => setCreatePopupOpen(false)} />
    </>
  );

  return (
    <TwoColumnLayout
      leftColumn={
        <Lists selectedListId={listId} onListSelection={handleListSelection} onCreateList={handleCreateList} />
      }
      rightColumn={center}
      hideNavigationOnMobile
    />
  );
};

export default ListsParent;
