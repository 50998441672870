import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";

import AgeVerificationPopup from "components/age-verification/AgeVerificationPopup";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import MaloumClientContext from "contexts/MaloumClientContext";
import { getThreeDSBrowserData } from "helper/checkoutHelper";
import { useAgeVerification, useCheckout } from "hooks/checkout";
import { useStoredCreditCard } from "hooks/checkout/useStoredCreditCard.hook";
import { CheckoutPosthogEvents, useCheckoutPosthog } from "hooks/useCheckoutPosthog";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useMutation, useQuery } from "react-query";
import { createSearchParams } from "react-router-dom";
import useUserStore from "state/userState";
import OrderType from "../../enums/OrderType";
import useCurrency from "../../hooks/useCurrency.hook";
import Button from "../basics/Button";
import { ProfileImageWithLink } from "../basics/ProfileImage";
import PopUp from "../popup/PopUp";
import TextWithCheckMark from "../utilities/TextWithCheckMark";

type Props = {
  creatorId: string;
  open: boolean;
  setOpen: (value: boolean) => void;
};

const SubscribePopup = ({ creatorId, open, setOpen }: Props) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const { checkoutSubscription } = useCheckout();
  const { userNeedsAgeVerification } = useAgeVerification();

  const { data: subscriptionInformation, isLoading } = useQuery(
    `fetch-${creatorId}-subscription-information`,
    () => maloumClient.users.getUsersSubscriptionInformation(creatorId),
    {
      staleTime: 1000 * 60 * 2,
      enabled: open,
    },
  );

  const [isAgeVerificationPopupOpen, setIsAgeVerificationPopupOpen] = useState(false);
  const getOverrideAgeVerificationRedirect = () => {
    if (!subscriptionInformation) return;
    return `${window.location.origin}/checkout?${createSearchParams({
      type: OrderType.Subscription,
      id: subscriptionInformation.username,
    }).toString()}`;
  };
  const currentUser = useUserStore<PrivateUserDto>((state) => state.user);
  const price = subscriptionInformation?.subscriptionPrice;

  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();
  const navigate = useStatefulNavigate();
  const storedCreditCard = useStoredCreditCard(currentUser);

  const { capture } = useCheckoutPosthog();

  const handleBuySubscription = async () => {
    if (!currentUser) return;

    if (userNeedsAgeVerification()) {
      setIsAgeVerificationPopupOpen(true);
      return;
    }

    const body = {
      creatorId,
      address: { country: currentUser.address.country },
      paymentMethod: {
        paymentMethod: currentUser.storedPaymentInformation[0].paymentMethod,
        referenceId: currentUser.storedPaymentInformation[0].referenceUUID,
      },
      threeDSBrowserData: getThreeDSBrowserData(),
    };
    // @ts-expect-error 3DS typing error
    await checkoutSubscription(body).then((r) => window.location.replace(r.redirectUrl));
  };

  const handleBuySubscriptionMutation = useMutation(handleBuySubscription);

  const goToCheckout = useCallback(() => {
    //as there is a loading state this will always be true, just there for type safety
    if (!subscriptionInformation) return;

    // Google Tag Manager: Subscription Go To Checkout Event
    // @ts-expect-error window.dataLayer is not typed
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error window.dataLayer is not typed
    window.dataLayer.push({
      event: "subscription_go_to_checkout",
      creator_id: creatorId,
      net_price_per_month: subscriptionInformation.subscriptionPrice.net,
    });
    capture(CheckoutPosthogEvents.SubscriptionCheckout);
    navigate({
      pathname: "/checkout",
      search: createSearchParams({
        type: OrderType.Subscription,
        id: subscriptionInformation.username,
      }).toString(),
    });
  }, [creatorId, subscriptionInformation]);

  const handleClosePopup = useCallback(() => setOpen(false), []);

  useEffect(() => {
    if (open) capture(CheckoutPosthogEvents.SubscriptionPopupOpen);
  }, [open]);

  const subscribePopupContent = (
    <Fragment>
      <div className={"flex justify-center py-6"}>
        <ProfileImageWithLink
          to={`/creator/${subscriptionInformation?.username}`}
          url={subscriptionInformation?.profilePictureThumbnail?.url}
          className={"h-24 w-24"}
        />
      </div>
      <div className="pb-8 text-left">
        <TextWithCheckMark
          text={t("subscription.fullAccess", {
            number: subscriptionInformation?.privatePostCount,
          })}
        />
        <TextWithCheckMark text={t("subscription.alwaysNewContent")} />
        <TextWithCheckMark text={t("subscription.cancelAtAnyTime")} />
        {subscriptionInformation?.hasChatRestriction && <TextWithCheckMark text={t("unlockChat")} />}
      </div>
      {price && storedCreditCard ? (
        <div className="flex flex-col items-center">
          <div className="flex w-full justify-between text-xs text-gray-400 xs:text-sm">
            <span>{t("subscription.name")}</span>
            <span>{displayCurrency(price.net)}</span>
          </div>
          {price.paymentFeeAmount > 0 && (
            <div className="flex w-full justify-between text-xs text-gray-400 xs:text-sm">
              <span>{t("checkout.serviceFee")}</span>
              <span>{displayCurrency(price.paymentFeeAmount)}</span>
            </div>
          )}
          <div className="flex w-full justify-between text-xs text-gray-400 xs:text-sm">
            <span>{t("checkout.VAT")}</span>
            <span>{displayCurrency(price.vatAmount)}</span>
          </div>
          <div className="mt-0.5 flex w-full justify-between text-xl font-semibold">
            <span>{t("checkout.total")}</span>
            <span>{t("checkout.monthly", { price: displayCurrency(price.gross) })}</span>
          </div>
          <Button
            type="submit"
            text={
              handleBuySubscriptionMutation.isLoading
                ? undefined
                : t("subscription.subscribeWith", { hint: storedCreditCard.hint })
            }
            IconFront={
              handleBuySubscriptionMutation.isLoading
                ? () => <AiOutlineLoading3Quarters className="my-0.5 h-5 w-5 animate-spin" />
                : undefined
            }
            className="mt-4"
            onClick={handleBuySubscriptionMutation.mutate}
            disabled={handleBuySubscriptionMutation.isLoading}
          />
          <TernaryButton
            type="submit"
            className="mt-2 w-full text-sm"
            onClick={goToCheckout}
            disabled={handleBuySubscriptionMutation.isLoading}
          >
            <div className="w-full text-center">{t("tips.chooseDifferentPaymentMethod")}</div>
          </TernaryButton>
        </div>
      ) : (
        <Button onClick={goToCheckout} text={t("subscribeFor", { price: displayCurrency(price?.net ?? 0) })} />
      )}
      <AgeVerificationPopup
        open={isAgeVerificationPopupOpen}
        setOpen={setIsAgeVerificationPopupOpen}
        overrideRedirect={getOverrideAgeVerificationRedirect()}
      />
    </Fragment>
  );

  return (
    <PopUp title={t("subscription.benefits") as string} isOpen={open} onClose={handleClosePopup}>
      {isLoading ? <SubscribePopupLoading withStoredCreditCard={!!storedCreditCard} /> : subscribePopupContent}
    </PopUp>
  );
};

type SubscribePopupLoadingProps = {
  withStoredCreditCard?: boolean;
};

const SubscribePopupLoading = ({ withStoredCreditCard }: SubscribePopupLoadingProps) => {
  return (
    <Fragment>
      <div className="relative flex flex-col items-center justify-center pt-6">
        <div className="mb-4 h-24 w-24 animate-pulse rounded-full bg-gray-200" />
      </div>
      <div className="relative mt-4 flex w-full flex-col items-start justify-start gap-4">
        <div className="w- flex w-full justify-between">
          <div className={`h-4 w-48 animate-pulse rounded-md bg-gray-200`} />
          <div className="h-4 w-6 animate-pulse rounded-full bg-gray-200" />
        </div>
        <div className="w- flex w-full justify-between">
          <div className={`w-36animate-pulse h-4 rounded-md bg-gray-200`} />
          <div className="h-4 w-6 animate-pulse rounded-full bg-gray-200" />
        </div>
        <div className="w- flex w-full justify-between">
          <div className={`h-4 w-32 animate-pulse rounded-md bg-gray-200`} />
          <div className="h-4 w-6 animate-pulse rounded-full bg-gray-200" />
        </div>
        <div className="w- flex w-full justify-between">
          <div className={`h-4 w-32 animate-pulse rounded-md bg-gray-200`} />
          <div className="h-4 w-6 animate-pulse rounded-full bg-gray-200" />
        </div>
        {withStoredCreditCard ? (
          <div className="relative mt-6 flex w-full flex-col items-start justify-start gap-3">
            <div className="w- flex w-full justify-between">
              <div className={`h-2 w-20 animate-pulse rounded-md bg-gray-200`} />
              <div className="h-2 w-10 animate-pulse rounded-full bg-gray-200" />
            </div>
            <div className="w- flex w-full justify-between">
              <div className={`h-2 w-20 animate-pulse rounded-md bg-gray-200`} />
              <div className="h-2 w-10 animate-pulse rounded-full bg-gray-200" />
            </div>
            <div className="w- flex w-full justify-between">
              <div className={`h-2 w-10 animate-pulse rounded-md bg-gray-200`} />
              <div className="h-2 w-10 animate-pulse rounded-full bg-gray-200" />
            </div>
            <div className="w- flex w-full justify-between">
              <div className={`h-6 w-24 animate-pulse rounded-md bg-gray-200`} />
              <div className="h-6 w-24 animate-pulse rounded-full bg-gray-200" />
            </div>
          </div>
        ) : (
          <div className="h-3 w-full" />
        )}
        <div className="mt-1 h-10 w-full animate-pulse rounded-md bg-gray-200" />
        {withStoredCreditCard && <div className=" h-10 w-full animate-pulse rounded-md bg-gray-200" />}
      </div>
    </Fragment>
  );
};

export default SubscribePopup;
