import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import ChatInboxes from "components/chats/ChatInboxes";
import ChatsForCreators from "components/chats/ChatsForCreators";
import FanInfo from "components/chats/FanInfo";
import { MessageBarRef } from "components/chats/MessageBar";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import ThreeColumnLayout from "components/layouts/ThreeColumnLayout";
import EmptyChatSelection from "components/molecules/chat/EmptyChatSelection";
import { MessageBarContext } from "contexts/MessageBarContext";
import { useMemo, useRef, useEffect } from "react";
import { ChatTabContext } from "contexts/ChatTabContext";
import { useTranslation } from "react-i18next";
import { HiOutlineChartBarSquare, HiOutlineCog6Tooth, HiOutlinePencilSquare } from "react-icons/hi2";
import { useParams, useSearchParams } from "react-router-dom";
import useUserStore from "state/userState";
import Container from "../../components/layouts/Container";
import Chat from "./Chat";

const ChatsParent = () => {
  const { t } = useTranslation();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { id } = useParams();
  const messageBarRef = useRef<MessageBarRef>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useRef<number | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const messageBarContextValue = useMemo(
    () => ({
      focusMessageBar: () => messageBarRef.current?.focus(),
    }),
    [],
  );

  useEffect(() => {
    if (!id && scrollPosition.current !== null) {
      scrollContainerRef.current?.scrollTo({ top: scrollPosition.current });
      scrollPosition.current = null;
    }
  }, [id]);

  const handleScroll = () => {
    if (scrollContainerRef.current && !id) {
      scrollPosition.current = scrollContainerRef.current.scrollTop;
    }
  };

  const chatTabContextValue = useMemo(
    () => ({
      switchToPrimaryTab: () => {
        searchParams.delete("tab");
        setSearchParams(searchParams);
      },
    }),
    [searchParams, setSearchParams],
  );

  const leftSide = (
    <>
      <div className="sticky top-0 z-50 mx-auto w-full bg-white sm:max-w-xl">
        <div className="md:px-2">
          <HeaderBar alwaysShowDivider={user?.isCreator} className="md:px-2">
            <HeaderBar.SubPage className="sm:pb-2 sm:pt-4">
              <HeaderBar.MainPageHeading className="-mb-1.5 !py-0 text-xl font-semibold !text-gray-700">
                {t("navigation.chat")}
              </HeaderBar.MainPageHeading>
              {user.isCreator && (
                <HeaderBar.MainPageActions className="items-center">
                  <HeaderBar.Link to={user.isVerified ? "/chat/new" : "/verification/start"} className="md:!p-0.5">
                    <HiOutlinePencilSquare className="h-6 w-6 text-gray-800" />
                  </HeaderBar.Link>
                  <HeaderBar.Link
                    to={user.isVerified ? "/chat/broadcasts" : "/verification/start"}
                    className="md:!p-0.5"
                  >
                    <HiOutlineChartBarSquare className="h-6 w-6 text-gray-800" />
                  </HeaderBar.Link>
                  <HeaderBar.Link to={user.isVerified ? "/chat/settings" : "/verification/start"} className="md:!p-0.5">
                    <HiOutlineCog6Tooth className="h-6 w-6 text-gray-800" />
                  </HeaderBar.Link>
                </HeaderBar.MainPageActions>
              )}
            </HeaderBar.SubPage>
          </HeaderBar>
        </div>
      </div>

      <Container noDesktopPadding hasPadding={false} hideScrollbar className="relative bg-white md:px-2" as="main">
        {user.isCreator ? <ChatsForCreators /> : <ChatInboxes />}
      </Container>
    </>
  );

  const center = id ? (
    <Chat messageBarRef={messageBarRef} />
  ) : (
    <>
      {/* use layout with navigation bar (bottom) for up to sm */}
      <div className="sm:hidden">
        <Layout>
          <Container>{leftSide}</Container>
        </Layout>
      </div>

      {/* then use layout with navigation bar (side) for md and up */}
      <div className="hidden sm:block md:hidden">
        <Container>{leftSide}</Container>
      </div>
      <div className="hidden min-h-screen md:block">
        <EmptyChatSelection />;
      </div>
    </>
  );
  const right = id && user.isCreator ? <FanInfo /> : null;

  return (
    <ChatTabContext.Provider value={chatTabContextValue}>
      <MessageBarContext.Provider value={messageBarContextValue}>
        <div className="h-full overflow-x-hidden" ref={scrollContainerRef} onScroll={handleScroll}>
          <ThreeColumnLayout hideNavigationOnMobile leftColumn={leftSide} centerColumn={center} rightColumn={right} />
        </div>
      </MessageBarContext.Provider>
    </ChatTabContext.Provider>
  );
};

export default ChatsParent;
