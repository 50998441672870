import { SubscriptionDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useContext } from "react";
import { useQuery } from "react-query";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useOutgoingSubscriptions = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchOutgoingSubscriptions = useCallback(async (): Promise<{ data: SubscriptionDto[] }> => {
    return await maloumClient.users.getOutgoingSubscriptions().then((subscriptions: { data: SubscriptionDto[] }) => {
      if (subscriptions instanceof Error) {
        throw Error;
      }
      return subscriptions;
    });
  }, []);

  const {
    data: subscriptions,
    isLoading,
    isError,
  } = useQuery("fetch-outgoing-subsriptions", fetchOutgoingSubscriptions);

  return { subscriptions: subscriptions?.data, isLoading, isError };
};

export default useOutgoingSubscriptions;
